//	Breakpoints

@mixin bp-lg {
	@media (max-width: 980px) {
		@content;
	}
}

@mixin bp-md {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin bp-sm {
	@media (max-width: 480px) {
		@content;
	}
}

@mixin bp-custom($args) {
	@media (max-width: $args) {
		@content;
	}
}