@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Regular.ttf") format("ttf"),
    url("./assets/fonts/Lato/Lato-Bold.ttf") format("ttf");
  font-weight: 400; /* You can set different weights */
  font-style: normal; /* or italic if applicable */
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Bold.ttf") format("ttf");
  font-weight: 700; /* Set the weight for bold */
  font-style: normal;
}