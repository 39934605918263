// Topic Banner Block

.topic-banner-block {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.topic-banner-content {
    position: relative;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 3 / 1;

    @include bp-lg {
        aspect-ratio: 2 / 1;
    }
    
    @include bp-sm {
        aspect-ratio: 3 / 2;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        

        & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}