// Projects Block

.topic-block {
    width: 100%;
    max-width: 100%;
    background-color: $c-grey-xl;
    min-height: calc(100vh - 10rem);

    @include bp-lg {
        min-height: calc(100vh - 8rem);
    }
}

.topic-block-content {
    width: 100%;
    max-width: $mw-content;
    margin: 0 auto;
    padding: $prow;

    @include bp-lg {
        padding: $prow-lg;
    }

    @include bp-md {
        padding: $prow-md;
    }

    @include bp-sm {
        padding: $prow-sm;
    }

    &__title {
        font-family: $f-main;
        font-weight: 700;
        font-size: 3.6rem;
        margin-bottom: $spc-r;

        @include bp-lg {
            font-size: 3.2rem;
        }

        @include bp-md {
            font-size: 2.8rem;
        }

        @include bp-sm {
            font-size: 2.4rem;
            margin-bottom: $spc-s;
        }
    }    

    &__text {
        margin-bottom: $spc-m;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $spc-l;
        margin-bottom: $spc-m;
        
        @include bp-lg {
            grid-template-columns: repeat(2, 1fr);
            gap: $spc-m;
        }

        @include bp-md {
            grid-template-columns: repeat(1, 1fr);
        }

        @include bp-md {
            gap: $spc-r;
        }
    }
}