// Import Fonts

@font-face {
    /* Lato Light */
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    src: local("Lato"), url("../../fonts/Lato/Lato-Light.ttf");
  }
  
  @font-face {
    /* Lato Regular */
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    src: local("Lato"), url("../../fonts/Lato/Lato-Regular.ttf");
  }
  
  @font-face {
    /* Lato Bold */
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    src: local("Lato"), url("../../fonts/Lato/Lato-Bold.ttf");
  }
  
  @font-face {
    /* Lato Black */
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    src: local("Lato"), url("../../fonts/Lato/Lato-Black.ttf");
  }
  
  /* Accent Fonts */
  
  @font-face {
    /* Londrina Solid Regular */
    font-family: LondrinaSolid;
    font-style: normal;
    font-weight: normal;
    src: local("LondrinaSolid"), url("../../fonts/LondrinaSolid/LondrinaSolid-Regular.ttf");
  }
  
  @font-face {
    /* Luckiest Guy */
    font-family: LuckiestGuy;
    font-style: normal;
    font-weight: normal;
    src: local("LuckiestGuy"), url("../../fonts/LuckiestGuy/LuckiestGuy-Regular.ttf");
  }

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	line-height: 1;
	margin: 0 0 1.6rem 0;
	padding: 0;
}

h1  {
	font-size: 5rem;
	
	@include bp-lg {
		font-size: 3.6rem;
	}
	
	@include bp-md {
		font-size: 3.2rem;
	}    
}

h2 {
	font-size: 3.2rem;
	
	@include bp-lg {
		font-size: 2.8rem;
	}
	
	@include bp-md {
		font-size: 2.4rem
	}  
}

h3 {
	font-size: 2.4rem;
	
	@include bp-lg {
		font-size: 2rem;
	}
	
	@include bp-md {
		font-size: 1.8rem
	}  
}

h4 {
	font-size: 2rem;
	
	@include bp-lg {
		font-size: 1.8rem;
	}
	
	@include bp-md {
		font-size: 1.6rem
	}  
}

h5 {
	font-size: 1.6rem;
}

h6 {
	font-size: 1.4rem;
}

p {	
	line-height: $lh-body;
	margin-block-start: 0rem;
	margin-block-end: 0rem;
	margin-bottom: 1.8rem;
	
	&:last-child {
		margin-bottom: 0;
	}
}

p.small {
	font-size: 1.2rem;
}

a {
	color: red;
	text-decoration: underline;
	transition: $transition;
	&:hover {
		color: $c-black;
	}
}

a.tel-link {
	text-decoration: none;
	
	color: inherit;
	
	&:hover {
		text-decoration: none;
		
		color: inherit;
	}
}

b, strong {
	font-weight: 600;
}

i {
	font-style: italic;
}