// Generate Code Block

.passcode-block {
    width: 100%;
    max-width: 100%;
    background-color: $c-grey-xl;
    
}

.passcode-block-content {
    width: 100%;
    max-width: $mw-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $spc-l;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   // padding: $spc-l;

    &__desc {
        
    }

    &__action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        @include bp-md {
            align-items: flex-start;
        }
    }
    
    @include bp-lg {
        gap: $spc-m;
        padding: $spc-m;  
    }

    @include bp-md {
        grid-template-columns: repeat(1, 1fr);
    }

    @include bp-sm {
        gap: $spc-r;
        padding: $spc-r;
    }

    &__title {
        font-family: $f-main;
        font-weight: 900;
        font-size: 2.4rem;
        margin-bottom: $spc-xs;
    }    

    &__text {

    }
}
