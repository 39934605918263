.app-table-content {
  overflow-x: scroll;
  padding: $spc-r;
  background-color: $c-white;
  box-shadow: $bs-card;
  border-radius: $br-lg;
}

.app-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: $spc-m;

    & thead th, tbody tr {
        border: none;
        vertical-align: middle;
        padding: $spc-s;
    }

    & tbody tr:nth-child(2n + 2) {
        background-color: $c-white;
    }

    & tbody tr:nth-child(2n + 1) {
        background-color: $c-grey-xl;
    }

    & tbody tr, & tbody td, & tbody th {
        padding: $spc-s;
        vertical-align: middle;
    }

    &__button  {
      width: 0px;
      white-space: nowrap;
    }
}




.th-table-container {
    overflow-x: scroll;
    padding: $spc-r;
    background-color: $c-white;
    box-shadow: $bs-card;
    border-radius: $br-lg;
  }
  
  .th-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: $spc-m;
  }
  
  .th-table tbody tr:nth-child(2n + 2) {
    background-color: var(--th-white);
  }
  
  .th-table tbody tr:nth-child(2n + 1) {
    background-color: var(--th-light-grey);
  }
  
  .th-table td, .th-table th {
    padding: var(--spc-s);
  }
  
  .th-table thead th,
  .th-table tbody tr {
    border: none;
  }
  
  .th-table tr th {
    vertical-align: middle;
  }
  
  .th-table tr td {
    vertical-align: middle;
  }
  
  .th-table tr td label {
    margin-bottom: 0;
  }
  
  .th-table-action-col {
    width: 0px;
    white-space: nowrap;
  }