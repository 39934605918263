// Search

.app-search-bar {
    margin-top: $h-header;
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    
    background-color: $c-white;
    box-shadow: rgba(32, 32, 36,0.2) 0px 12px 16px 0px;
    padding: $spc-m;
    transition: $transition;
    z-index: 8;

    @include bp-lg {
      margin-top: $h-header-sm;
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: $spc-r;
    }
}

.app-search-filters {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--spc-s);
  margin-top: var(--spc-s);

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spc-r);
  }

}

.btn-filter {
  background-color: transparent;
  color: $c-black;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 300;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $c-purple;

    & i {
      color: $c-purple;
      transform: scale(1.15);
      transform-origin: center;
    }
  }

  & i {
    color: $c-black;
    margin-right: $spc-xs;
    transition: all 0.3s ease-in-out;
  }
}

.app-search-bar.hide {
    top: -100%;
    opacity: 0;
}

.app-search-bar.show {
    opacity: 1;
}

// /* Search Bar */

// .search-bar {
//     margin-top: var(--navHeight);
//     width: 100vw;
//     height: auto;
//     background-color: white;
//     position: fixed;
//     padding: var(--spc-l);
//     top: 0;
//     left: 0;
//     transition: all 0.3s ease-in-out;
//     box-shadow: rgba(32, 32, 36,0.2) 0px 12px 16px 0px;
//   //  overflow: hidden;
//     z-index: 8;
//   }
//   .search-bar.hide {
//     top: -100%;
//     opacity: 0;
//   }
//   .search-bar.show {
//     opacity: 1;
//   }
//   .search-bar-container {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     gap: var(--spc-r);
//   }
//   .filter-controls {
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     gap: var(--spc-r);
//   }
//   .filter-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     gap: var(--spc-s);
//     margin-top: var(--spc-s);
//   }

//   .filter-btn {
//     background-color: transparent;
//     color: var(--th-thundercloud);
//     border: none;
//     padding: 0;
//     margin: 0;
//     font-size: 0.75rem;
//     font-weight: 300;
//     transition: all 0.3s ease-in-out;
//   }
//   .filter-btn i {
//     color: var(--th-thundercloud);
//     margin-right: var(--spc-xs);
//     transition: all 0.3s ease-in-out;
//   }
//   .filter-btn:hover {
//     color: var(--th-purple);
//   }
//   .filter-btn:hover i {
//     color: var(--th-purple);
//     transform: scale(1.15);
//     transform-origin: center;
//   }