// Navigation

.app-menu {
    position: fixed;
    top:0;
    left:0;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #ffffff;
    transition: $transition;
    z-index: 8;

    &--hide {
        width: 380px;
        margin-left: -380px;
        box-shadow: none;

        @include bp-sm {
            width: 100%;
            margin-left: -100%;
        }
    }

    &--show {
        width: 380px;
        margin-left: 0px;
        box-shadow: rgba(32, 32, 36,0.2) 12px 0px 16px 0px;

        @include bp-sm {
            width: 100%;
            margin-left:0%;
        }
    }
}

.app-menu-content {
    position: relative;
    margin-top: $h-header;
    overflow-y: auto;
    width: 100%;


    &__title {
        font-size: 2rem;
        font-weight: 300;
        padding: 4rem 4rem 2rem 4rem;
        margin: 0;
        color: $c-purple;
    }

    &__link, &__link.button {
        font-size: 1.6rem;
        border-bottom: 1px solid var(--th-light-grey-2);
        color: var(--th-dark-grey-3);
        display: block;
        font-weight: 300;
        padding: var(--spc-r) var(--spc-l);
        text-decoration: none;
        text-align: left;
        transition: $transition;
        width: 100%;

        & i {
            color: var(--th-dark-grey-3);
            margin-right: var(--spc-r);
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            color: var(--th-dark-grey-3);
            background-color: var(--th-light-grey-1);
            border-bottom: 1px solid transparent;
            text-decoration: none;

            & i {
                transform: scale(1.25);
                transform-origin: center;
                color: var(--th-purple);
            }
        }
    }
}

.app-menu-footer {
    width: 100%;
    border-top: 1px solid var(--th-light-grey-2);
    padding: var(--spc-m) var(--spc-l);
    font-weight: 300;

    &__text {
        font-size: 1.4rem;
        margin-bottom: 10px;
    }

    &__link {
        color: inherit;
        text-decoration: underline;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: var(--th-purple);
            text-decoration: none;
        }

    }

}