// Banner Block

.welcome-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $lg-yellow;
}

.welcome-content {
    width: 100%;
    max-width: $mw-content;
    margin: 0 auto;
    padding: $prow;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include bp-lg {
        padding: $prow-lg;
    }

    @include bp-md {
        padding: $prow-md;
    }

    @include bp-sm {
        padding: $prow-sm;
    }

    &__title {
        font-family: $f-main;
        font-weight: 900;
        font-size: 3.6rem;
        text-align: center;
        margin-bottom: $spc-r;

        @include bp-lg {
            font-size: 3.2rem;
        }

        @include bp-md {
            font-size: 2.8rem;
        }

        @include bp-sm {
            font-size: 2.4rem;
            margin-bottom: $spc-s;
        }
    }    

    &__text {
        max-width: 66rem;
        text-align: center;
        margin-bottom: $spc-m;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $spc-l;
        margin-bottom: $spc-m;
        
        @include bp-lg {
            grid-template-columns: repeat(2, 1fr);
            gap: $spc-m;
        }

        @include bp-md {
            grid-template-columns: repeat(1, 1fr);
        }

        @include bp-md {
            gap: $spc-r;
        }
    }

    &__grid_col_2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spc-l;
        margin-bottom: $spc-m;
        
        @include bp-lg {
            grid-template-columns: repeat(2, 1fr);
            gap: $spc-m;
        }

        @include bp-md {
            grid-template-columns: repeat(1, 1fr);
        }

        @include bp-md {
            gap: $spc-r;
        }
    }
}

.welcome-card {
    background-color: $c-white;
    border-radius: $br-lg;
    width: 100%;
    max-width: 40rem;
    padding: $spc-m;
    box-shadow: $bs-card;
}

.welcome-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spc-m;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid $c-grey-l;
        margin: 0 auto $spc-r auto;

        & i {
            color: $c-purple;
            font-size: 3rem;
        }
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;
        line-height: 1;
        margin-bottom: $spc-s;
    }

    &__text {
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 0;
    }
}

.welcome-toggle {
    position: absolute;
    bottom: $spc-l;
    right: $spc-l;

    @include bp-md {
        position: relative;
        bottom: initial;
        right: initial;
    }

    & .app-field-wrapper--inline label {
        font-size: 1.4rem;
    }
}
