@import '../css/normalise.css';

@import 'utils/variables';
@import 'utils/mixins';

@import 'base/base';
@import 'base/type';

@import 'layout/header.scss';
@import 'layout/navigation.scss';
@import 'layout/search';
@import 'layout/welcome-block';
@import 'layout/generate-code';
@import 'layout/cookie-banner';

@import 'componants/buttons';
@import 'componants/forms';
@import 'componants/tables';
@import 'componants/topic-cards';
@import 'componants/project-cards';
@import 'componants/topic-banner';

@import 'template-parts/login-block';
@import 'template-parts/register-block.scss';
@import 'template-parts/register-form-block';
@import 'template-parts/banner-block.scss';
@import 'template-parts/slider-block';
@import 'template-parts/related-slider-block';
@import 'template-parts/section-block';
@import 'template-parts/child-account-block';
@import 'template-parts/topic-block';
@import 'template-parts/topic-info-block';
@import 'template-parts/address-block';
@import 'template-parts/reset-password-block';

