// Projects Block

.address-block {
    width: 100%;
    max-width: 100%;
    

    &__grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: $spc-r;
        margin-bottom: $spc-m;
    }
}

.address-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap:0;

    padding: $spc-r;
    background-color: $c-white;
    box-shadow: $bs-card;
    border-radius: $br-lg;

    @include bp-md {
        flex-direction: column;
        align-items: flex-start;
        gap: $spc-r;
    }
}

.address-content {
    display: flex;
    flex-direction: row;
    gap: $spc-r;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid $c-grey-l;

        & i {
            color: $c-purple;
            font-size: 3rem;
        }
    }
}

.address-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__title {
        color: $c-purple;
        font-family: $f-main;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: $spc-xs;
    }

    &__text {
        font-size: 1.6rem;
    }
}

.address-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: $spc-r;
}