// Projects Block

.section-block {
    width: 100%;
    max-width: 100%;
    background-color: $c-white;

    &--grey-xl {
        background-color: $c-grey-xl;
    }

    &--fh {
        min-height: calc(100vh - $h-header);

        @include bp-lg {
            min-height: calc(100vh - $h-header-sm);
        }
    }

    &--fixed-add-child {
        position: fixed;
        top: $h-header;
        left: 0;
        width: 100%;
        height: calc(100% - 10rem);

        @include bp-lg {
            top: $h-header-sm;
            height: calc(100% - 8rem);
        }
    }
}

.section-block-content {
    width: 100%;
    max-width: $mw-content;
    margin: 0 auto;
    padding: $prow;

    @include bp-lg {
        padding: $prow-lg;
    }

    @include bp-md {
        padding: $prow-md;
    }

    @include bp-sm {
        padding: $prow-sm;
    }

    &__title {
        font-family: $f-main;
        font-weight: 900;
        font-size: 3.6rem;
        margin-bottom: $spc-r;

        @include bp-lg {
            font-size: 3.2rem;
        }

        @include bp-md {
            font-size: 2.8rem;
        }

        @include bp-sm {
            font-size: 2.4rem;
            margin-bottom: $spc-s;
        }
    }
    
    &__subtitle {
        font-family: $f-main;
        font-weight: 900;
        font-size: 2.8rem;
        margin-bottom: $spc-r;

        @include bp-lg {
            font-size: 2.4rem;
        }

        @include bp-md {
            font-size: 2rem;
        }

        @include bp-sm {
            font-size: 2rem;
            margin-bottom: $spc-s;
        }
    }

    &__text {
        margin-bottom: $spc-m;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $spc-l;
        
        @include bp-lg {
            grid-template-columns: repeat(2, 1fr);
            gap: $spc-m;
        }

        @include bp-md {
            grid-template-columns: repeat(1, 1fr);
        }

        @include bp-md {
            gap: $spc-r;
        }
    }
}