/* FORMS */

.app-form {
    width: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--spc-l);  
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spc-r);

        @include bp-md {
            flex-direction: column;
            gap: var(--spc-l);
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: $spc-r;
    }


}

.app-field-wrapper {
    position: relative;
    width:100%;

    & input[type=text],
    & input[type=password],
    & input[type=email],
    & select,
    & textarea {
        appearance: none;
        outline: none;

        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid var(--th-light-grey-3);
        font-size: 1.6rem;
        font-weight: 300;
        resize: none;
        padding: 12px 20px;
        margin: 0;
        transition: $transition;
        width: 100%;
    }

    & input[type=text]:hover,
    & input[type=email]:hover,
    & input[type=password]:hover,
    & textarea:hover {
        border-color: var(--th-mid-grey-1);
    }

    & input[type=text]:focus,
    & input[type=email]:focus,
    & input[type=password]:focus,
    & textarea:focus {
        border-color: var(--th-purple);
    }   

    & label{
        background-color: #ffffff;
        width: auto;
        color: var(--th-purple);
        font-size: 1.2rem;
        font-weight: 700;
        padding: 4px;
        position: absolute;
        top: -14px;
        left: 5px;
    }

    &__desc label {
        font-size: 1.2rem;
        margin: 1rem 0 0 0;
        padding: 0;
    }

    &--inline {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spc-s);

        & label {
            position: relative;
            top: auto;
            left: auto;
            margin: 0;
            padding: 0;

            color: $c-black;
            font-size: 1.2rem;
            font-weight: 300;
        }
    }

    &--inline-col {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spc-s);

        @include bp-lg {
            flex-direction: column;
            align-items: flex-start;
        }

        & label {
            position: relative;
            top: auto;
            left: auto;
            margin: 0;
            padding: 0;

            color: $c-black;
            font-size: 1.2rem;
            font-weight: 300;
        }
    }

    &--button {
        width: auto;
    }
}

.notify-bar{
    background: var(--th-yellow);
    width : 50%;
    font-weight: 500;
    border-radius: 10px;
    padding: var(--spc-xs);
}




// /* Forms */

.th-form-container {
    width:100%;
}
.th-form-inner {
    display: flex;
    flex-direction: column;
    gap: var(--spc-l);  
    padding-left: var(--spc-xl);
    padding-right: var(--spc-l);
}
.th-form-inner p {
    margin-top: 0;
    margin-bottom: 0;
}
.th-form-inner-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spc-r);
}
.th-form-inline-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spc-s);
}
.th-form-inline-label label {
    font-size: 0.80rem;
    margin-top: 0;
}

.th-form-inner-register-verify {
    display: flex;
    flex-direction: column;
    gap: var(--spc-s);  
}

.th-field-wrapper  {
    position: relative;
    width:100%;
}
.th-field-wrapper.th-sml-field  {
    max-width: 40%;
}
.th-field-wrapper label {
    background-color: #ffffff;
    width: auto;
    color: var(--th-purple);
    font-size: 0.75rem;
    font-weight: 700;
    padding: 4px;
    position: absolute;
    top: -14px;
    left: 5px;
}
.th-field-wrapper input[type=text],
.th-field-wrapper input[type=password],
.th-field-wrapper input[type=email],
.th-field-wrapper select,
.th-field-wrapper textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid var(--th-light-grey-3);
    font-size: 1rem;
    font-weight: 300;
    resize: none;
    padding: 12px 20px;
    margin: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
}
.th-field-wrapper input[type=text]:hover,
.th-field-wrapper input[type=email]:hover,
.th-field-wrapper textarea:hover {
    border-color: var(--th-mid-grey-1);
}
.th-field-wrapper input[type=text]:focus,
.th-field-wrapper input[type=email]:focus,
.th-field-wrapper textarea:focus {
border-color: var(--th-purple);
}
.th-field-wrapper input[type=text]::placeholder,
.th-field-wrapper input[type=email]::placeholder,
.th-field-wrapper textarea::placeholder {
    color: var(--th-mid-grey-1);
}
.th-form-inner label {
    margin: 0;
}
.th-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
.th-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.th-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--th-steel);
    -webkit-transition: .4s;
    transition: .4s;
}
.th-switch-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--th-white);
    -webkit-transition: .4s;
    transition: .4s;
}
    .th-switch input:checked + .th-switch-slider {
    background-color: var(--th-yellow);
}
    .th-switch input:focus + .th-switch-slider {
    box-shadow: 0 0 1px var(--th-yellow);
}
.th-switch input:checked + .th-switch-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.th-switch-slider.round {
    border-radius: 34px;
}
.th-switch-slider.round:before {
    border-radius: 50%;
}
label>span {
    min-width: auto !important;
}

.login-label{
  color: var(--th-purple);
}

/* Form Cards */

.form-card-container {
    max-width: 400px;
    background-color: #ffffff;
    border-radius: var(--spc-s);
}
.form-card-inner-container {
    padding: var(--spc-l);
}
.th-form-card-inner {
    display: flex;
    flex-direction: column;
    gap: var(--spc-m);  
}
.form-card-container h2 {
    font-size: 28px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 1rem;
}
.form-card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--th-light-grey-2);
}
.form-card-icon i {
     color: var(--th-black);
     font-size: 30px;
}

.form-card-footer {
    border-top: 1px solid var(--th-light-grey-3);
    padding: var(--spc-r) var(--spc-l);
}
.form-card-footer p {
    text-align: center;
    font-size: 16px;
    margin:0;
}


/* MEDIA QUERIES */


/* Tablet */

@media only screen and (max-width: 980px) {

}

/* Large Mobile */

@media only screen and (max-width: 767px) {

}

/* Mobile */

@media only screen and (max-width: 480px) {
    .th-form-inner-row {
        flex-direction: column;
      }
      .th-field-wrapper.th-sml-field {
        max-width: 100%;
      }
}