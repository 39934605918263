// Topic Info Block

.topic-info-block {
    width: 100%;
    max-width: 100%;
    background-color: $c-white;

    &--grey-xl {
        background-color: $c-grey-xl;
    }

    &--fh {
        min-height: calc(100vh - $h-header);

        @include bp-lg {
            min-height: calc(100vh - $h-header-sm);
        }
    }

    &--fixed-add-child {
        position: fixed;
        top: $h-header;
        left: 0;
        width: 100%;
        height: calc(100% - 10rem);

        @include bp-lg {
            top: $h-header-sm;
            height: calc(100% - 8rem);
        }
    }
}

.topic-info-content {
    width: 100%;
    max-width: $mw-content;
    margin: 0 auto;
    padding: $prow;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spc-l;

    @include bp-lg {
        padding: $prow-lg;
        grid-template-columns: repeat(1, 1fr);
        gap: $spc-m;
    }

    @include bp-md {
        padding: $prow-md;
    }

    @include bp-sm {
        padding: $prow-sm;
        gap: $spc-r;
    }

    &__title {
        font-family: $f-main;
        font-weight: 900;
        font-size: 3.6rem;
        margin-bottom: $spc-r;

        @include bp-lg {
            font-size: 3.2rem;
        }

        @include bp-md {
            font-size: 2.8rem;
        }

        @include bp-sm {
            font-size: 2.4rem;
            margin-bottom: $spc-s;
        }
    }    

    &__text {
        margin-bottom: $spc-m;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $spc-l;
        
        @include bp-lg {
            grid-template-columns: repeat(2, 1fr);
            gap: $spc-m;
        }

        @include bp-md {
            grid-template-columns: repeat(1, 1fr);
        }

        @include bp-md {
            gap: $spc-r;
        }
    }
}

.topic-info {
    grid-column: span 2;

    @include bp-lg {
        grid-column: auto;
    }

    &__title {
        font-family: $f-main;
        font-weight: 900;
        font-size: 3.6rem;
        margin-bottom: $spc-s;

        @include bp-lg {
            font-size: 3.2rem;
        }

        @include bp-md {
            font-size: 2.8rem;
        }

        @include bp-sm {
            font-size: 2.4rem;
            margin-bottom: $spc-s;
        }
    }

    &__subtitle {
        font-family: $f-main;
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: $spc-r;

        @include bp-md {
            font-size: 1.8rem;
            margin-bottom: $spc-s;
        }
    }

    &__intro-text {
        color: $c-purple;
        font-family: $f-secondary;
        font-size: 2.4rem;
        line-height: 1.2;
        margin-bottom: $spc-r;

        @include bp-md {
            font-size: 1.8rem;
            margin-bottom: $spc-s;
        }
    }

    &__text {
        margin-bottom: $spc-m;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: $spc-r;

        border-top: 1px solid $c-grey-l;
        padding-top: $spc-m;
    }
}

.topic-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spc-r;
    margin-bottom: $spc-m;

    @include bp-md {
        grid-template-columns: repeat(2, 1fr);
    }
}

.topic-resources {
    padding: 0 0 0 $spc-l;
    border-left: 1px solid $c-grey-l;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spc-r;

    @include bp-lg {
        padding: $spc-m 0;
        border-left: none;
        border-top: 1px solid $c-grey-l;
        gap: $spc-s;
    }

    &__title {
        font-family: $f-main;
        font-weight: 900;
        font-size: 2rem;
        margin-bottom: $spc-r;

        @include bp-md {
            font-size: 1.8rem;
            margin-bottom: $spc-s;
        }
    }

    &__text {
        color: $c-grey;
        font-size: 1.6rem;
        
        &:last-child {
            margin-bottom: 0;
        }


    }
}

.sponsor-block {
    border-top: 1px solid $c-grey-l;
    padding-top: $spc-r;
    width: 100%;

    &__text {
        color: $c-black;
        font-size: 1.6rem;
        margin-bottom: $spc-s;
    }

    &__sponsor {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: $spc-r;
    }

    &__image {
        max-width: 200px;
        border: 1px solid $c-grey-l;
        margin-bottom: $spc-s;
        
        & img {
            max-width: 100%;
            height: auto;
        }
    }
}
