// Banner Block

.hero-banner-block {
    position: relative;
    overflow: hidden;
    z-index: 1;
    // margin-top: $h-header;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 700px;

    &--fh {
      min-height: calc(100vh - 10rem);

      @include bp-lg {
        min-height: calc(100vh - 8rem);
      }

    }
    
    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: all 0.3s ease-in-out;
        z-index: -1;
    }

    &__spaceman {
        position: absolute;
        width: 25%;
        height: auto;
        left: 0;
        bottom: 30%;
        animation: float;
        animation-duration: 8s;
        animation-iteration-count: infinite;

        @include bp-md {
          width: 45%;
          bottom: 10%;
        }
       
    }

    &__moon {
        position: absolute;
        top: 50%;
        left: 110%;
        width: 66vw;
        height: 66vw;
        animation: slide-in-moon 1.5s;
        animation-timing-function: ease-in-out;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease-in-out;

        @include bp-custom(1200px) {
          top: 0%;
          width: 100vw;
          height: 100vw;
        }

        & img {
            position: absolute;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: center;
            transition: all 0.3s ease-in-out;
        }

        &--focus {
            transform: translate(-50%, -50%) rotate(16deg) scale(1.1);
        }
    }
}

.hero-banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $mw-content;
    padding: 10rem 4rem;

    &__title {
        color: $c-white;
        font-family: $f-headline;
        font-size: clamp(3.2rem, 6vw, 5.6rem);
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    &__text {
        color: $c-white;
        text-align: center;
        max-width: 66rem;
        margin-bottom: $spc-m;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: $spc-s;

      & p {
        color: $c-white;
        font-size: 1.4rem;
        font-weight: 700;
        margin: 0;
      }
    }

}

// Animations

  @keyframes float {
    0% {
      transform: translateY(0px) translateX(-20px) rotate(0) scale(1);
    }
  
    50% {
      transform: translateY(60px) translateX(-60px) rotate(9deg) scale(1.2);
    }
  
    100% {
      transform: translateY(0px) translateX(-20px) rotate(0) scale(1);
    }
  }

  @keyframes slide-in-moon {
    0% {
      left: 150%;
    }
  
    100% {
      left: 110%;
    }
  }

 