// Project Topic Card

.project-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative; 
    z-index: 1;
    background-color: $c-white;
    text-decoration: none;
    border-radius: 0.8rem;
    box-shadow: $bs-card;
}

.project-card-content {
    width: 100%;
    max-width: 100%;

    &__image {
        position: relative;
        aspect-ratio: 3 / 2;
        margin: $spc-s $spc-s 0 $spc-s;
        border-radius: .4rem;
        
        overflow: hidden;

        &:hover img {
            transform: scale(1.05);
            transform-origin: center;
        }

        & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-width: 100;
            object-fit: cover;
            object-position: center;
            transition: $transition;
        }
    }
}

.project-card-desc {
    padding: $spc-r;

    &__title {
        color: $c-purple;
        font-size: 2.4rem;
        font-weight: 900;
        margin-bottom: $spc-xs;
        
    }

    &__text {
        color: $c-black;
        font-size: 1.6rem;
    }
}

.project-card-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-top: 1px solid $c-grey-xl;
    padding: $spc-r;
}

.project-info-btn {
    background-color: transparent;
    color: $c-black;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 300;
    transition: $transition;

    &:hover {
        color: $c-purple;

        & i {
            color: $c-purple;
            transform: scale(1.15);
            transform-origin: center;
        }
    }

    & i {
        color: $c-black;
        margin-left: $spc-xs;
        transition: $transition;
    }
}