// Related Slider

.related-slider {

    & .carousel.carousel-slider {
        overflow: visible;
    }

    &__slide-sml {
        position: relative;
        width: 100%;
        aspect-ratio: 3 / 2;
        cursor: pointer;
        

        & img {
            padding: $spc-xs;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.3s ease-in-out;
        }
    }

    // Arrows

    & .carousel.carousel-slider .control-arrow {
        display: none;
    }

    // Dots

    & ul.control-dots {
        position: absolute;
        margin: 0;
        top: calc(100% + .5rem);

        & li.dot {
            background: none;
            box-shadow: none;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            border: 1px solid $c-grey;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            cursor: pointer;
            display: inline-block;
            margin: 0 6px;

            &:hover {
                border: 1px solid $c-grey;
                background: $c-grey;
                box-shadow: none;
            }
        }

        & li.dot.selected {
            border: 1px solid $c-grey;
            background: $c-grey;
            box-shadow: none;
        }
    }
}