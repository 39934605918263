// Header

.app-header {
    position: fixed;
    width: 100%;
    height: $h-header;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-bottom: 1px solid var(--th-light-grey-2);
    z-index: 9;

    &__logo {
        width: 160px;
        margin-right: 4rem;

        @include bp-lg {
            width: 120px;
            margin-right: 3rem;
        }

        @include bp-lg {
            width: 140px;
            margin-right: 2rem;
        }

        & img {
            max-width: 100%;
            height: auto;
        }
    }

    @include bp-lg {
        height: $h-header-sm;
    }
}

.app-header-menu {
    display: flex;
    flex-direction: row;
    margin-left: 4rem;

    @include bp-lg {
        margin-left: 3rem;
    }

    @include bp-sm {
        margin-left: 2rem;
    }

    &__item {
        color: var(--th-dark-grey-3);
        background-color: transparent;
        border:none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-size: 2.8rem;
        margin-right: 1.5rem;
        outline: none;
        transition: all 0.3s ease-in-out;

        @include bp-lg {
            font-size: 2.4rem;
            margin-right: 1.2rem;
        }

        @include bp-sm {
            font-size: 2rem;
            margin-right: 1rem;
        }

        &:hover {
            transform: scale(1.15);
            transform-origin: center;
            color: var(--th-purple);
        }

        &:last-child {
            margin-right: 0;
        }
    }
}