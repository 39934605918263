// Register Block

.register-block {
    width: 100%;
    min-width: 100%;
    min-height: 100vh;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include bp-lg {
        grid-template-columns: repeat(1, 1fr);
    }
}

.register-block-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 40rem;
        border-radius: $br-lg;
        background-color: $c-white;
        box-shadow: $bs-card;
        padding: 4rem;
        
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1.6rem;
    }

    &__text {
        font-size: 1.8rem;
        font-weight: 300;
        text-align: center;
        margin-bottom: 3rem;
    }

    &--home {
        background-color: $c-yellow;
        background: linear-gradient(135deg, rgba(250, 210, 50, 1) 30%, rgba(249, 177, 4, 1) 100%);
    }

    &--school {
        background-color: $c-purple;
        background: linear-gradient(135deg, rgba(72, 38, 131, 1) 0%, rgba(231, 28, 119, 1) 100%);
    }
}