// Cookie Banner

.cookie-banner-block {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-top: 1px solid $c-grey-xl;
    background-color: #ffffff;
    z-index: 9999;
}

.cookie-banner-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $spc-m;
    padding: $spc-m;

    &__text {
        color: $c-grey;
        margin-bottom: 0;
    }
}

.cookie-banner-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $spc-r;
}
