.btn {
    display: inline-flex;
    max-width: max-content;
    min-width: 140px;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: $spc-xs;

    font-size: 1.8rem;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    padding: 1.4rem 2.6rem;
    transition: $transition;

    @include bp-md {
        font-size: 1.6rem;
    }

    &--sml {
        font-size: 1.4rem;
        padding: 1.2rem 2.4rem;
    }

    &--primary {
        color: $c-black;
        background-color: $c-yellow;
        border: 1px solid $c-yellow;

        &:hover {
            color: $c-black;
            background-color: transparent;
        }
    }

    &--secondary {
        color: $c-black;
        background-color: $c-grey-l;
        border: 1px solid $c-grey-l;

        &:hover {
            color: $c-black;
            border: 1px solid $c-grey-l;
            background-color: transparent;
        }
    }

    &--primary-fw {
        display: flex;
        justify-content: center;
        max-width: 100%;
        width: 100%;
        color: $c-black;
        background-color: $c-yellow;
        border: 1px solid $c-yellow;

        &:hover {
            color: $c-black;
            background-color: transparent;
        }
    }

    &--primary-reverse {
        color: $c-black;
        background-color: $c-yellow;
        border: 1px solid $c-yellow;

        &:hover {
            color: $c-white;
            background-color: transparent;
        }
    }

    &--success {
        color: $c-white;
        background-color: $c-green;
        border: 1px solid $c-green;

        &:hover {
            color: $c-green;
            background-color: transparent;
        }
    }

    &--danger {
        color: $c-white;
        background-color: $c-red;
        border: 1px solid $c-red;

        &:hover {
            color: $c-red;
            background-color: transparent;
        }
    }
}

.back-btn {
    cursor: pointer;
    position: absolute;
    top: 4rem;
    left: 4rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    color: $c-grey;
    font-family: $f-main;
    font-size: 1.4rem;
    font-weight: 600;
    transition: $transition;

    & i {
        font-size: 2.2rem;
        margin-right: .5rem;
    }

    &:hover {
        color: $c-black;
    }

    @include bp-lg {
        align-self: flex-start;
        position: relative;
        top: auto;
        left: auto;
        margin-bottom: 3rem;
    }
    
}