/* Header */

:root {
    --navHeight: 100px;
}

@media only screen and (max-width: 980px) {
    :root {
      --navHeight: 100px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    :root {
      --navHeight: 80px;
    }
  }

// .th-navbar {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     background: #ffffff;
//     position: fixed;
//     width: 100%;
//     height: var(--navHeight);
//     top: 0;
//     left: 0;
//     border-bottom: 1px solid var(--th-light-grey-2);
//     z-index: 9;
// }
// .nav-icon-container {
//     display: flex;
//     flex-direction: row;
//     margin-left: var(--spc-l);
// }
// .nav-icon  {
//     color: var(--th-dark-grey-3);
//     background-color: transparent;
//     border:none;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     font-size: 28px;
//     margin-right: var(--spc-r);
//     outline: none;
//     transition: all 0.3s ease-in-out;
// }
// .nav-icon:last-child  {
//     margin-right: 0;
// }
// .nav-icon:hover  {
//     transform: scale(1.15);
//     transform-origin: center;
//     color: var(--th-purple);
// }
// .navbar-logo {
//     width: 160px;
//     margin-right: var(--spc-l);
// }
// .navbar-logo img {
//     max-width: 100%;
//     height: auto;
// }
// .notification {
//     position: absolute;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     top: -3px;
//     right: -3px;
//     width: 14px;
//     height: 14px;
//     color: white;
//     text-align: center;
//     line-height: 1;
//     font-size: 0.5rem;
//     background-color: red;
//     border-radius: 50%;
// }

/* Side Navigation */

// .side-menu {
//     background: #ffffff;
//     position: fixed;
//     top:0;
//     left:0;
//     height: 100%;
//     transition: all 0.3s ease-in-out;
//     z-index: 8;
// }
// .menu-hide {
//     width: 380px;
//     margin-left: -380px;
//     box-shadow: none;
// }
// .menu-show {
//     width: 380px;
//     margin-left: 0px;
//     box-shadow: rgba(32, 32, 36,0.2) 12px 0px 16px 0px;
// }
// .mw-push {
//     left:380px;
// }
// .side-menu-container {
//     margin-top: var(--navHeight);
//     position: relative;
//     overflow-y: auto;
//     height: calc(100vh - 250px);
// }
// .side-menu-title {
//     padding: var(--spc-l) var(--spc-l) var(--spc-r) var(--spc-l);
// }
// .side-menu-title h1 {
//     font-size: 20px;
// }
// .side-menu-footer p {
//     margin-bottom: 10px;
// }
// .side-menu-footer a {
//     color: inherit;
//     text-decoration: underline;
//     transition: all 0.3s ease-in-out;
// }
// .side-menu-footer a:hover {
//     color: var(--th-purple);
//     text-decoration: none;
// }
// .side-menu-footer {
//     position:absolute;
//     width: 100%;
//     bottom: 0;
//     left: 0;
//     border-top: 1px solid var(--th-light-grey-2);
//     padding: var(--spc-m) var(--spc-l);
//     font-weight: 300;
// }
// .side-menu-item, button.side-menu-item {
//     border-bottom: 1px solid var(--th-light-grey-2);
//     color: var(--th-dark-grey-3);
//     display: block;
//     font-weight: 300;
//     padding: var(--spc-r) var(--spc-l);
//     text-decoration: none;
//     text-align: left;
//     transition: all 0.3s ease-in-out;
//     width: 100%;
// }
// .side-menu-item:hover {
//     color: var(--th-dark-grey-3);
//     background-color: var(--th-light-grey-1);
//     border-bottom: 1px solid transparent;
//     text-decoration: none;
// }
// .side-menu-item i {
//     color: var(--th-dark-grey-3);
//     margin-right: var(--spc-r);
//     transition: all 0.3s ease-in-out;
// }
// .side-menu-item:hover i  {
//     transform: scale(1.25);
//     transform-origin: center;
//     color: var(--th-purple);
// }

// /* Search Bar */

// .search-bar {
//     margin-top: var(--navHeight);
//     width: 100vw;
//     height: auto;
//     background-color: white;
//    // position: fixed;
//     padding: var(--spc-l);
//     top: 0;
//     left: 0;
//     transition: all 0.3s ease-in-out;
//     box-shadow: rgba(32, 32, 36,0.2) 0px 12px 16px 0px;
//   //  overflow: hidden;
//     z-index: 8;
//   }
//   .search-bar.hide {
//     top: -100%;
//     opacity: 0;
//   }
//   .search-bar.show {
//     opacity: 1;
//   }
//   .search-bar-container {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     gap: var(--spc-r);
//   }
//   .filter-controls {
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     gap: var(--spc-r);
//   }
//   .filter-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     gap: var(--spc-s);
//     margin-top: var(--spc-s);
//   }
  // .filter-btn {
  //   background-color: transparent;
  //   color: var(--th-thundercloud);
  //   border: none;
  //   padding: 0;
  //   margin: 0;
  //   font-size: 0.75rem;
  //   font-weight: 300;
  //   transition: all 0.3s ease-in-out;
  // }
  // .filter-btn i {
  //   color: var(--th-thundercloud);
  //   margin-right: var(--spc-xs);
  //   transition: all 0.3s ease-in-out;
  // }
  // .filter-btn:hover {
  //   color: var(--th-purple);
  // }
  // .filter-btn:hover i {
  //   color: var(--th-purple);
  //   transform: scale(1.15);
  //   transform-origin: center;
  // }
  
  .loader-container {
    width: 100vw;
    min-height: calc(100vh - var(--navHeight));
  }
  
  .loader-container i {
    font-size: 3rem;
    color: var(--th-steel);
  }

/* MEDIA QUERIES */

/* Tablet */

// @media only screen and (max-width: 980px) {
//     .nav-icon  {
//         width: 30px;
//         font-size: 1.5rem;
//         margin-right: var(--spc-s)
//     }
    // .navbar-logo {
    //     width: 120px;
    //     margin-right: var(--spc-l);
    // }  
// }

// /* Large Mobile */

// @media only screen and (max-width: 767px) {

// }

/* Mobile */

// @media only screen and (max-width: 480px) {
//     .menu-hide {
//         width: 100%;
//         margin-left: -100%;
//       }
//       .menu-show {
//         width: 100%;
//         margin-left:0%;
//       }
//       // .mw-push {
//       //   margin-left:100%;
//       // }
// }