:root {
    --font-family: var(--bs-font-sans-serif);
    --printess-maxContrastColor: black;
    --printess-headlineColor: red;
  
    --editor-margin-left: 30px;
    --editor-margin-top: 30px;
    --editor-margin-bottom: 30px;
    --editor-margin-right: 15px;
    --editor-space-between: 25px;
  
    --mobile-panel-background: #f8f8f8;
    --mobile-button-background: yellow;
    --mobile-button-border: #bbb;
    --mobile-button-border-selected: red;
    --mobile-button-circle-gauge: red;
    --mobile-button-circle-selected: rgba(0,0,0, 0.1);
    --mobile-button-color: #444;
    --mobile-button-square-bg: #dddddd;
    --mobile-button-square-bg-selected: #cccc00;
    --mobile-button-square-color: var(--mobile-button-color);
    --mobile-button-image-border: var(--mobile-button-border); /* rgba(255, 255, 255, 0.5);*/
   
    --mobile-printess-z-index: 10;
    --mobile-ui-z-index: 15;
    --layout-snippet-btn-z-index: 20;
    --dropdown-z-index: 25;
  
    --mobile-buttonbar-height: 78px;
    --mobile-pagebar-height: 48px;
    --mobile-navbar-height: 48px;
  }
  
  body.inline-mobile-page-bar {
    --mobile-pagebar-height: 0px;
  }
  body.no-mobile-button-bar {
    --mobile-buttonbar-height: 0px;
  }
  
  body.mobile-has-steps-header {
    --mobile-navbar-height: 78px;
  }
  hr {
    margin: 0;  
  }
  
  html {
    height: -webkit-fill-available; /* to counter safari 100vh bug */
  }
  
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    height: -webkit-fill-available; /* to counter safari 100vh bug */
  }
  
  /* Main Printess Desktop-Layout */ 
  /* Mobile Layout is positioned absolute in media queries at the end of this file */ 
  /* Vertical position of printess needs to be absolute to counter the 100vh safari-bug */
  #printess-desktop-grid {
    position: absolute; 
    height: initial;
    top: 0px;
    bottom: 0px; 
  
    width: calc(100vw - var(--editor-margin-left));
    margin-left: var(--editor-margin-left);
   
    display: grid;
    grid-template-rows: calc(50px + var(--editor-margin-top)) 1fr var(--editor-margin-bottom);
    grid-template-columns: 1fr 450px;
    column-gap: var(--editor-space-between);
  }
  
  #desktop-pagebar {
    width:  100%;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding-top: var(--editor-margin-top);
  }
  #desktop-pagebar .undo-redo-bar {
    display: flex;
    align-self: flex-start;
    padding-top: 3px;
  }
  #desktop-pagebar .icon {
    width:20px;
    height:20px;
    color: inherit;
  } 
  #desktop-printess-container {
    outline: 1px solid gray;
  }
  
  #desktop-properties {
    grid-row: 1 / 4;
    grid-column: 2;
    display: flex;
    height: -webkit-fill-available; /* safari only */
    flex-direction: column;
    padding-right: 5px;
    padding-top: var(--editor-margin-top);
    overflow-y: auto;
    padding-right: var(--editor-margin-right);
  }
  
  .desktop-title-bar {
    align-items: flex-end;
    display: grid;
    grid-template-columns: minmax(auto, 1fr) auto 10px;
  }
  .desktop-title-bar.active-step {
     grid-template-columns: auto 1fr auto auto  ;
  }
  .desktop-title-bar.active-step-only-badge {
     grid-template-columns: 1fr auto auto auto  ;
  }
  .desktop-title-bar.active-step-badge-list {
     grid-template-columns: auto 1fr auto  ;
  }
  
  .desktop-title-bar.active-step-only-badge  .step-badge {
    margin-left: 8px;
    margin-right: 7px;
    align-self: center;
  }
  .desktop-title-bar.steps {
     grid-template-columns:  minmax(auto, 1fr) auto auto  ;
  }
  .step-n-of {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .step-n-of > * {
   margin-bottom: 0;
  }
  
  .badge-list{
    display: flex;
    padding-left: 1px;
    padding-right: 1px;
    align-self: center;
  }
  .badge-list .step-badge {
    margin: 0 2px 0 2px !important;
    cursor: pointer;
  }
  .badge-list .step-badge:first {
    margin-left: 7px !important;
  }
  .badge-list .step-badge:right {
    margin-right: 10px !important;
  }
  
  .desktop-title-bar > h2 {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  
   
  .token-warning {
    display: none; 
    border: 3px solid red;
    position: absolute;
   
    bottom: -3px;
    
    width: 100vw;
      left:0;
    
    background-color: yellow;
    z-index: 9999999;
    font-family: sans-serif;
    padding: 5px;
  }
  .token-warning > h4 {
    margin: 0 0 2px 0;
  }
  
  .offcanvas-start {
    /* override bootsrap setting, breaks pages smaller then 400px */
    max-width: 500px !important;
    width: 100vw !important;
  }
  
  input[type="radio"]:checked+label > svg {
    fill: white;;
  }
  .layout-snippet-list {
    display: flex;
    flex-direction: column;
  }
  .layout-snippet-cluster {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
   
  .mobile-group-snippets-container { 
    padding-left: 15px;
    height: 100%;
    width: 100%;
    overflow: hidden auto;
  }
   
  .group-snippets { 
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  .snippet-thumb {
    cursor:pointer;
    width: 100px; 
    height: 100px;
    margin: 2px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .snippet-thumb.big {
    width: 140px; 
    height: 140px;
   
  }
  .snippet-thumb.big > img {
    border: 1px solid var(--mobile-button-border);
  }
  .snippet-thumb:hover {
    border: 1px solid var(--mobile-button-border);
  }
  .snippet-thumb.big:hover   {
    border: 1px solid transparent;
  }
  .snippet-thumb.big:hover > img {
    border: 1px solid var(--mobile-button-border-selected);
  }
  .snippet-thumb > img {
    max-width: 100%; 
    max-height: 100%;
    background-color: white;
  }
  
  .snippet-cluster-name {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 3px;
  }
  
  .show-layouts-button {
    position: absolute;
    visibility: hidden;
    left: 0px;
    top: calc(50% - 200px);
    display: flex;
    transform: rotate(90deg);
    transform-origin: 0% 100%;
    z-index: var(--layout-snippet-btn-z-index);
  }
  
  #json {
    position: absolute;
    left: 1150px;
    top: 100px;
    display: flex;
    flex-direction: column;
  
    width: 500px;
    font-family: monospace;
    font-size: 9pt;
  }
  
  label {
    width: calc(100% - 20px);
    font-family: var(--font-family);
  }
  
  label>span {
    display: inline-block;
    min-width: 100px;
  }
  
  input[type='text'] {
    font-size: 16pt;
    padding: 3px;
  }
  
  #desktop-properties > * {
    margin-bottom: 10px;
  }
  
  input[type='range'] {
    vertical-align: middle;
    margin-left: 10px;
  }
  
  
  .image-rotate-panel {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 110px;
  }
  .image-rotate-panel img {
    border: 1px solid rgba(0,0,0,1);
  }
  
  .image-panel {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 110px;
  }
  .image-panel > .main {
    width: 105px;
    height: 105px;
    margin-right: 5px;
    background-size: cover;
    background-position:center center;
  }
  
  
  .image-list-wrapper {
    width: 100%;
    overflow-y: auto;
  }
  
  .image-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .image-list>* {
    width: 50px;
    height: 50px;
    margin: 0px 5px 5px 0;
  
    background-color: rgb(215, 232, 235);
    background-size: contain;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    border: 1px solid black;
  }
  .image-list > .big {
    width: 105px;
    height: 105px;
    margin-right: 5px;
    background-size: cover;
    background-position:center center;
    cursor:grab;
  }
  
  
  .image-select-list-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .image-select-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .image-select-list>* {
    width: 120px;
    height: 120px;
    margin: 5px;
    background-size: contain;
    background-position: 0% 0%;
    background-repeat: no-repeat;
  }
  
  .image-upload-btn {
    padding: 0px;
    width: 100%;
  }
  
  .image-upload-label {
    cursor: pointer;
    display: inline-block !important;
    margin: 0px;
    padding: .375rem .75rem;
    width: 100%;
  }
  
  .delete-btn {
    display: none;
    width: 28px;
    height: 28px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #ad1700;
    background-color: white;
    border-radius: 4px;
    box-shadow: 2px 2px black;
    cursor: pointer;
  }
  
  div.big:hover > .delete-btn {
    display: block;
  }
  
  .text-properties {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  
  
  .step-badge {
    align-self: center;
  
    background-color: red;
    color: white;
    width: 32px;  
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    font-family: Roboto, sans-serif;
    text-align: center;
  
    border-radius: 50%;
    margin-top: 2px;
    margin-right: 7px;
  }
  
  .step-badge.gray {
    background-color: rgba(0,0,0,0.4);
  }
  
  .step-badge.outline {
    background-color: white;
    border: 1px solid red;
    color: red;
  }
   
  
  .step-badge.outline.gray {
    background-color: transparent;
    border-color: gray;
    color: gray;
  }
  
  .step-badge > svg {
    width: 22px;
    height: 22px;
    vertical-align: text-top;
  }
  
  .step-badge-sm {
    width: 22px;  
    height: 22px;
    line-height: 22px;
    font-size: 14px;
  }
   
  .step-badge.selectable:hover {
    background-color: red;
  }
  .step-badge.outline.selectable:hover {
    background-color: white;
    border-color: red;
    color: red;
  }
  
  /*
   *  Simple Dropdown with imgage
   */ 
  
   .dropbtn {
    background-color: #f9f9f9;
    border: 1px solid gray;
    color: rgb(58, 58, 58);
    padding: 6px 12px 6px 0px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    border: 1px solid rgb(118, 118, 118);
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    top: 30px;
    background-color: #f9f9f9;
    border: 1px solid gray;
    min-width: 160px;
    width: 100%;
    box-shadow: 0px 8px 16px
      0px rgba(0, 0, 0, 0.2);
    z-index: var(--dropdown-z-index);
  }
  
  .dropdown-content a {
    color: black;
    padding: 6px 12px 6px 0px;
    
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1
  }
  
  .dropdown.show .dropdown-content {
    display: block;
  }
  /*.dropdown:hover .dropdown-content {
    display: block;
  }*/
  
  .dropdown:hover .dropbtn {
    background-color: #ebe9e9;
  }
  
  .dropdown-list-entry {
    pointer-events: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  
  .dropdown-list-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .dropdown-list-label {
    font-size: 12pt;
  }
  
  #desktop-properties .dropdown-toggle.full-width::after {
    /* tweaking the bootstrap class for 100% width controls */
    position: absolute;
    top: 50%;
    right: 10px;
  }
   
  /*
   **** COLOR PICKER ***
   */
  
   .color-drop-down-list {
     display: flex;
     flex-direction: row;
   }
   /* overide bootstrap behaviour */
   .list-group-item .dropdown-item:focus, .list-group-item .dropdown-item:hover {
    background-color: transparent;
    /*color: white; kills mobile form-lists */
   }
  
  .color-picker-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: #bbb 1px solid  ;
    flex: 0 0 auto !important; /* override bootstrap behaviour */
  }
  .color-picker-drop-down {
    display: flex; 
    flex-flow: row wrap;
    justify-content: center;
  }
  .color-picker-color {
    width: 30px !important; /* otherwise overwritten by bootstrap theme link style*/
    height: 30px; 
    position: relative; 
    background-color: rgb(0, 0, 0); 
    border-radius: 50%;
    border: #bbb 1px solid;
    margin-left: 2px;
    margin-top: 2px;
    padding: 0;
  }
  #desktop-properties .color-drop-down-list .btn-color-select:after {
    position: absolute;
    top: 45%;
    right: 12px;
    color: white;
  }
  #desktop-properties .color-drop-down-list .btn-color-select {
    border-radius: 50%;
    min-width: 39px;
    min-height: 39px;
    padding: 2px;
    border: 1px solid lightgray;
  }
  
  
  
  
  
  /*
   ************** MOBILE UI ***************
   */
  
   .mobile-ui {
    display: none;
    position: absolute;
    bottom: 0;
    width: inherit;
    left: 0;
    right: 0;
    height: var(--mobile-buttonbar-height);
    z-index: var(--mobile-ui-z-index);
    border-top: 2px solid black;
    background-color:  var(--mobile-panel-background);
    transition: height 0.5s;
    
   }
   
   
  .mobile-control-host {
    height:0px;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 5px 5px;
    background-color:  var(--mobile-panel-background);
    background-color: lavender;
    overflow: hidden auto;
  }
  .mobile-control-sm {
    height: 80px;
  }
  .mobile-control-md {
    height: 120px;
  }
  .mobile-control-lg {
    height: 160px;
  }
  .mobile-control-xl {
    height: 200px;
  }
  .mobile-control-overlay {
    height: 450px;
  }
  
  .no-mobile-button-bar .mobile-control-host {
    /* add additional padding to control host, to increase space between control and back arrow */
    padding-top: 30px;
  }
  
  .mobile-control-host > .list-group {
    /* mobile list elements occupie a fixed height */
    height: 140px;
  }
  
  .mobile-control-host > ul {
    height: 100%;
  }
  
  .align-control-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .align-control-item {
    width: auto;
    padding: 0;
    border: none;
    margin: 4px;
  }
   
  .list-group-grid-style {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
  }
  .list-group-grid-style > li {
      width: 50px;
      margin: 4px;
      border-width: 1px !important;
      border-radius: 4px;
      cursor: pointer;
      padding: 0.5rem 0.5rem;
      text-align: center;
  }
                  
  .mobile-buttons-container {
    
    position: absolute;
    top:0;
    left: 0;
    right: 0 ;
    height: var(--mobile-buttonbar-height);
    border-bottom: 1px solid var(--mobile-button-border);
    padding-top: 10px;
    background-color:  var(--mobile-panel-background);
  }
   
  .mobile-buttons-scroll-container {
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  /* hide scrollbar safari, chrome, opera */ 
  .mobile-buttons-scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .mobile-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: -webkit-fill-available;
    width: -webkit-fit-content;
    width: fit-content;
    min-width: 40px;
  }
  
  .mobile-property-button {
    width: fit-content;
    overflow: hidden;     
    margin-right: 20px;
    display: grid;
    grid-template-rows: 38px 3px 14px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    position: relative;
    cursor: pointer;
  }
  .mobile-property-text {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid var(--mobile-button-border);
    padding: 3px;
    font-size: 11px;
    overflow: hidden;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    position: relative;
    cursor: pointer;
  }
  .mobile-property-text.selected {
    border-color: var(--mobile-button-border-selected);
  }
  .mobile-property-text > .text {
    overflow: hidden;
    font-size: 10px;
  }
  .mobile-property-text > .icon {
    background-color: var(--mobile-button-square-bg);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: -13px;
    top: -14px;
    display: flex;
    place-items: flex-end;
    padding-left: 6px;
    font-family: serif;
  }
  .mobile-property-text.selected > .icon {
    background-color: var(--mobile-button-square-bg-selected);
  }
  .mobile-property-text > .icon > div {
    color: var(--mobile-button-square-bg);
    font-size: 14px;
  }
  .mobile-property-button:first-child,   .mobile-property-text:first-child {
    margin-left: 20vw;
  }
  .mobile-property-button:last-child, .mobile-property-text:last-child {
    margin-right: 45vw !important;
  }
  
  
  
  .mobile-property-circle {
    grid-row: 1;
    grid-column: 1;
    width: 38px ; 
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .mobile-property-circle.back-to-frames {
    background-color: lemonchiffon !important;
  }
  
  .circle-button-icon {
    align-self: center;
    justify-self: center;
  
    width: 20px; 
    height: 20px;
    grid-row: 1;
    grid-column: 1;
    fill: white;
  } 
  .mobile-property-caption {
    grid-row: 3;
    color:  var(--mobile-button-color);
    white-space: nowrap;
    font-size: 11px;
    text-align: center;
    z-index: 1;
  }
  
  .mobile-property-plus-button {
    position: absolute;
    right: 10px;
    top: -29px;
    width: 42px;
    height: 42px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    z-index: 90;
    cursor: pointer;
  }
    
    
  .mobile-property-plus-button >  .mobile-property-circle  {
    width: 42px;
    height: 42px;
    background-color: #afe713;
    box-shadow: 0px 10px 8px -4px rgba(0,0,0,0.38);
  }
      
  .mobile-property-plus-button > .mobile-property-circle:active {
    background-color: black;
    border: 2px solid lime;
  }
  
      
  .mobile-property-back-button {
    position: absolute;
    left: 10px;
    top: -30px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
    cursor: pointer;
  }
    
  .mobile-property-back-button >  .mobile-property-circle  {
    width: 44px;
    height:44px;
    background-color:  var(--mobile-panel-background);
    box-shadow: 0px 10px 8px -4px rgba(0,0,0,0.38);
  }
  
  .mobile-property-circle > *  {
    width: 22px;
    height: 22px;
  }
  
  
  .circle-button-graphic {
    display: grid;
  }
  
  /* overlay all child items */
  .circle-button-graphic > * {
    display: grid;
    grid-row: 1;
    grid-column: 1;
    fill: var(--mobile-button-color);
  }
  
  
                  
  .circular-svg {
    position: relative;
    z-index: 1;
    display: block;
    width: 42px;
    height:42px;
    margin: -2px; 
  }
  .mobile-property-button.selected .circle-bg{
    fill: var(--mobile-button-circle-selected);
  }
  .circular-color {
    justify-self: center;
    align-self: center;
    border-radius: 50%;
    width: 24px;
    height: 24px; 
    border: 1px solid white;
  }
  .mobile-property-button.selected .circular-color {
    border: 1px solid var(--printess-headlineColor);
  }
  .circular-image {
    justify-self: center;
    align-self: center;
    border-radius: 15%; 
    height: 36px; 
    width: 42px;
    background-size: cover;
    border: 1px solid var(--mobile-button-border);
    background-repeat: no-repeat;
    background-position: center;
  }
  .mobile-property-button.selected .circular-image {
    border: 1px solid var(--mobile-button-border-selected) !important;
  }
  .circle-bg {
    fill: none;
    stroke: var(--mobile-button-border);
    stroke-width: 1;
  }
  
  .circle {
    fill: none;
    stroke-width: 1;
    stroke-linecap: round;
  }
  
  .circular-svg .circle {
    stroke: var(--mobile-button-circle-gauge);
  }
  
  .circular-svg-caption {
    fill: var(--mobile-button-color);
    color: var(--mobile-button-color);
    z-index: 1;
    font-size: 10px;
    justify-self: center;
    align-self: center;
  }
  
  .form-field-caption {
    z-index: 1;
    place-self: center;
    padding: 3px 5px;
    background-color: var(--mobile-button-square-bg);
    color: var(--mobile-button-square-color);
    border-radius: 5px;
    max-width: 60px;
    text-align: center;
    font-size: 11px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 75px;
    overflow: hidden;
  }
  .mobile-property-button.selected .form-field-caption {
    background-color: var(--mobile-button-square-bg-selected);
    border: 1px solid var(--mobile-button-border-selected);
    
  }
  
  .mobile-text-area {
    padding-left: 10px;
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
  }
  .desktop-text-area {
    width: 100%;
  }
  
  .textButtonContainer {
    display: flex; 
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .textButtonContainer > button {
      padding: 4px 8px;
      font-family: Lato, sans-serif; /* is overwritten by user-agent style-sheet ??? */
      border-radius: 4px; 
      border: 0px solid var(--printess-textColor); 
      font-size: var(--printess-labelSize);
  }
  
  .mobile-navbar {
    display: none;
    position: absolute !important; /* to not be overidden by BS */
    flex-direction: column;
    left: 0;
    top: 0;
    right: 0;
  }
  .mobile-step-bar {
     height: 30px;
     overflow: hidden;
  /*  padding-bottom: 4px;
    margin-top: 0px;*/
  }
  
  .mobile-navbar .icon {
    width:24px;
    height:24px;
    color:white;
  }
  .mobile-navbar .main-button {
    min-width: 20px;
    white-space: nowrap;
  }
  .mobile-pagebar {
    display: none;
    position: absolute;
    height: var(--mobile-pagebar-height);
    width: 100%;
    padding: 4px;
    overflow: auto hidden;
    top: var(--mobile-navbar-height);
    left: 0;
    background-color: var(--mobile-panel-background);
    border-bottom: 2px solid black;
  }
  body.inline-mobile-page-bar .mobile-pagebar {
    visibility: hidden;
  }
  /* BS override: change aspect auf page-links to save spave on mobile and make it more look like a page */
  .pagination-lg .page-link {
    padding: .75rem 1rem;
  }
  .pagination-lg .page-ellipsis {
    padding: .75rem 0;
  }
   
  .mobile-pagebar-page-info { 
    position: absolute;
    top: 2px;
    width: 70px;
    left: calc(50% - 35px);
    font-family: var(--font-family);
    line-height: 17px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    color: var(--mobile-button-color); 
  }
  .mobile-pagebar-page-previous {
    left: calc(50% - 59px);
  }
  .mobile-pagebar-page-next {
    left: calc(50% + 35px);
  }
  .mobile-pagebar-page-previous, .mobile-pagebar-page-next {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    fill: var(--mobile-button-color);
    cursor: pointer;
  }
  
  
  /* Breakpoints force iPad and Mobile phones in landscape mode to show touch optimized mobile ui */
  
  @media screen and (min-width: 897px) {
    .token-warning {
      left: calc(50% - 190px);
      bottom: 0px;
      width: 380px;
    }
  }
  
  @media screen and (max-width: 896px) {
    #printess-desktop-grid {
      /* shrink the desktop-grid, mobile items are positioned absolute */
      margin: 0;
      grid-template-rows: 0 0;
      grid-template-columns: 0 0;
      column-gap: 0;
      height: 100px !important; /* to ensure its not bigger than the viewport */
    }
  
    #desktop-printess-container {
      box-shadow: none;
      border:  none;
    }
  
  
    .step-badge.outline.gray {
      background-color: transparent;
      border-color: white;
      color: white;
    }
  
    #printessin {
      position: absolute;
      z-index: var(--mobile-printess-z-index);
      top:calc(var(--mobile-navbar-height) + var(--mobile-pagebar-height));
      left: 0;
      right: inherit;
      bottom: inherit;
      border: none;
      box-shadow: none;
      width: 100vw !important;
      height: initial;
      bottom: var(--mobile-buttonbar-height);
      transition: bottom 0.5s ease-out, top 0.5s ease-out;
    }
    #desktop-properties, #json,  .token-warning, #desktop-pagebar {
      display: none !important;
    }
    .mobile-ui {
      display: block;
    }
    .mobile-pagebar { 
      display: block;
    }
    .desktop-navbar {
      display: none;
    }
    .mobile-navbar {
      display: flex;
    }
    .group-snippets > .snippet-thumb {
      width: 60px; 
      height: 60px;
    }
    
  }
   
  