// Type

$f-main: "Lato", sans-serif;
$f-secondary: "LondrinaSolid", cursive;
$f-headline: "LuckiestGuy", sans-serif;

//	Font size

$fs-body: 1.8rem;
$fs-body-sm: 1.6rem;
$lh-body: 1.5;

//  Layout

$h-header: 10rem;
$h-header-sm: 8rem;

$mw-content: 120rem;

//  Colours

$c-black: #000000;
$c-white: #ffffff;
$c-yellow: #fad232;
$c-purple: #871482;

$c-green: #32C878;
$c-red: #e3051b;

$c-grey: #999999;
$c-grey-xl: #fafafd;
$c-grey-l: #eeeef2;

$light-grey-2: #eeeef2;

$lg-yellow: linear-gradient(135deg, rgba(250, 210, 50, 1) 30%, rgba(249, 177, 4, 1) 100%);
$lg-puple: linear-gradient(135deg, rgba(72, 38, 131, 1) 0%, rgba(231, 28, 119, 1) 100%);
$lg-white: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(241, 235, 241) 100%);

// Space

$prow: 4rem 2rem;
$prow-lg: 6rem 3rem;
$prow-md: 4rem 3rem;
$prow-sm: 2rem 2rem;


$spc-xl: 6rem;
$spc-l: 4rem;
$spc-m: 3rem;
$spc-r: 2rem;
$spc-s: 1rem;
$spc-xs: 0.5rem;

// Misc

$br-lg: 0.8rem;
$bs-card: rgba(0,0,0,0.2) 0px 9px 24px 0px;
$transition: all 0.3s ease-in-out;