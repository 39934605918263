// Register Block

.register-form-block {
    width: 100%;
    min-width: 100%;
    min-height: 100vh;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include bp-lg {
        grid-template-columns: repeat(1, 1fr);
    }

    &--reverse {
        & .register-form-content {
            grid-column: 2;
            grid-row: 1;
        }
    }
}

.register-form-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;

    & .app-form {
        max-width: 66rem;
    }
}

.register-form-message {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    padding: 4rem;
    background-color: red;

    @include bp-lg {
        display: none;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.5);
            mix-blend-mode: multiply;
        }

        & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    &__quote {
        color: $c-white;
        font-family: $f-headline;
        font-size: 4rem;
        line-height: 1;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 1rem;
        z-index: 1;

        & span {
            color: $c-yellow;
        }
    }

    &__text {
        color: $c-white;
        font-size: 1.6rem;
        font-weight: 300;
        margin-bottom: 0;
        z-index: 1;
    }
}