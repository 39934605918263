

/* --- Variables --- */

:root {
  /* Colours */
  --th-black: #000000;
  --th-white: #ffffff;
  --th-yellow: #fad232;
  --th-purple: #871482;
  --th-green: #0bda51;
  --th-red: #e3051b;

  /* Greys */
  --th-light-grey-1: #fafafd;
  --th-light-grey-2: #eeeef2;
  --th-light-grey-3: #dee1e6;
  --th-mid-grey-1: #b9b9c3;
  --th-mid-grey-2: #a5a5af;
  --th-mid-grey-3: #96969e;
  --th-dark-grey-1: #3c3c46;
  --th-dark-grey-2: #323239;
  --th-dark-grey-3: #202024;
  --th-thundercloud: #323238;
  --th-slate: #46464b;
  --th-dolphin: #5f6469;
  --th-stone: #909096;
  --th-steel: #a5acb4;
  --th-rainy-day: #dee1e6;
  --th-snowfall: #f2f2f5;
  --th-light-grey: #f9f9f9;

  /* Space */
  --spc-xl: 48px;
  --spc-l: 36px;
  --spc-m: 24px;
  --spc-r: 16px;
  --spc-s: 12px;
  --spc-xs: 6px;
}

@media only screen and (max-width: 980px) {
  :root {
  }
}

@media only screen and (max-width: 480px) {
  :root {
    /* Space */
    --spc-xl: 40px;
    --spc-l: 30px;
    --spc-m: 20px;
    --spc-r: 16px;
    --spc-s: 10px;
    --spc-xs: 5px;
  }
}

/* --- General Typography --- */

// html {
//   // font-size: 100%;
//   /*16px*/
//   scroll-behavior: smooth;
// }

// .main-wrapper {
//   /* .main-wrapper used through text and space styles to dodge BS bullshit */
//   color: var(--th-black);
//   background: var(--th-white);
//   font-family: "Lato", sans-serif;
//   font-weight: 300;
//   font-style: normal;
//   left: 0;
//   line-height: 1.5;
//   padding-top: var(--navHeight);
//   position: relative;
//   transition: all 0.3s ease-in-out;
//   width: 100%;
// }

// .main-wrapper-public {
//   /* .main-wrapper used through text and space styles to dodge BS bullshit */
//   color: var(--th-black);
//   background: var(--th-white);
//   font-family: "Lato", sans-serif;
//   font-weight: 300;
//   font-style: normal;
//   left: 0;
//   line-height: 1.5;
//   position: relative;
//   transition: all 0.3s ease-in-out;
//   width: 100%;
// }

// .main-wrapper p {
//   margin-top: 0;
//   margin-bottom: 1rem;
// }

// .main-wrapper h1,
// .main-wrapper h2,
// .main-wrapper h3,
// .main-wrapper h4,
// .main-wrapper h5,
// .main-wrapper h6 {
//   line-height: 1;
//   margin: 0;
//   padding: 0;
// }

// .main-wrapper h1 {
//   font-size: 48px;
// }

// /* 3rem */
// .main-wrapper h2 {
//   font-size: 36px;
// }

// /* 2.25rem */
// .main-wrapper h3 {
//   font-size: 28px;
// }

// /* 1.75rem */
// .main-wrapper h4 {
//   font-size: 24px;
// }

// /* 1.5rem */
// .main-wrapper h5 {
//   font-size: 20px;
// }

// /* 1.25re */
// .main-wrapper h6 {
//   font-size: 18px;
// }

// /* 1rem */
// .main-wrapper small,
.t-sml {
  font-size: 12px;
}
.t-medium {
  font-size: 14px;
}

// /* 0.75rem */
// .main-wrapper ol,
// ul {
//   margin-top: 1rem;
//   margin-bottom: 1rem;
//   padding-left: 30px;
// }

// .main-wrapper li {
//   margin-bottom: 0.3125rem;
// }

// .main-wrapper li:last-child {
//   margin-bottom: 0;
// }

// .main-wrapper sup,
// .main-wrapper sub {
//   font-size: 60%;
//   line-height: 0;
//   position: relative;
//   vertical-align: baseline;
// }

// .main-wrapper sup {
//   top: -0.5em;
// }

// .main-wrapper sub {
//   bottom: -0.25em;
// }

a {
  color: var(--th-black);
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}

.link-text {
  text-decoration: underline;
}

a:hover {
  color: var(--th-purple);
  text-decoration: none;
}

button {
  background-color: transparent;
  line-height: 1;
  border: none;
  margin: 0;
  padding: 0;
}

a.button-link {
  text-decoration: none;
}

// @media only screen and (max-width: 980px) {
//   .main-wrapper h1 {
//     font-size: 40px;
//   }

//   /* 2.5rem */
//   .main-wrapper h2 {
//     font-size: 32px;
//   }

//   /* 2rem */
//   .main-wrapper h3 {
//     font-size: 28px;
//   }

//   /* 1.75rem */
//   .main-wrapper h4 {
//     font-size: 24px;
//   }

//   /* 1.5rem */
//   .main-wrapper h5 {
//     font-size: 20px;
//   }

//   /* 1.25rem */
//   .main-wrapper h6 {
//     font-size: 16px;
//   }

//   /* 1.125rem */
// }

// @media only screen and (max-width: 767px) {
//   .main-wrapper h1 {
//     font-size: 36px;
//   }

//   /* 2.25rem */
//   .main-wrapper h2 {
//     font-size: 30px;
//   }

//   /* 1.875re */
//   .main-wrapper h3 {
//     font-size: 26px;
//   }

//   /* 1.625rem */
//   .main-wrapper h4 {
//     font-size: 22px;
//   }

//   /* 1.375rem */
//   .main-wrapper h5 {
//     font-size: 18px;
//   }

//   /* 1.125rem */
//   .main-wrapper h6 {
//     font-size: 16px;
//   }

//   /* 1rem */
// }

// @media only screen and (max-width: 480px) {
//   .main-wrapper h1 {
//     font-size: 36px;
//   }

//   /* 2.25rem */
//   .main-wrapper h2 {
//     font-size: 30px;
//   }

//   /* 1.875re */
//   .main-wrapper h3 {
//     font-size: 26px;
//   }

//   /* 1.625rem */
//   .main-wrapper h4 {
//     font-size: 22px;
//   }

//   /* 1.375rem */
//   .main-wrapper h5 {
//     font-size: 18px;
//   }

//   /* 1.125rem */
//   .main-wrapper h6 {
//     font-size: 16px;
//   }

//   /* 1rem */
// }

/* Rows & Columns */
.d-grid {
  display: grid;
}

.d-grid p:last-child {
  margin-bottom: 0;
}

.topic-grid {
  grid-template-columns: repeat(3, 1fr);
}

.col-3-2-1 {
  grid-template-columns: repeat(3, 1fr);
}

.col-3-3-2 {
  grid-template-columns: repeat(3, 1fr);
}

.col-2-1-1 {
  grid-template-columns: repeat(2, 1fr);
}

.col-2-2-1 {
  grid-template-columns: repeat(2, 1fr);
}

.col-4-2-1 {
  grid-template-columns: repeat(4, 1fr);
}

.col-side-bar {
  grid-template-columns: 2fr 1fr;
}

.col-resources {
  padding: 0px 0px 0px var(--spc-l);
  border-left: 1px solid var(--th-rainy-day);
  border-right: none;
  border-top: none;
  border-bottom: none;
}

@media only screen and (max-width: 980px) {
  .col-resources {
    padding: var(--spc-l) 0px 0px 0px;
    border-left: none;
    border-right: none;
    border-top: 1px solid var(--th-rainy-day);
    border-bottom: none;
  }

  .col-side-bar {
    grid-template-columns: 1fr;
  }

  .topic-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .col-3-2-1 {
    grid-template-columns: repeat(2, 1fr);
  }

  .col-3-3-2 {
    grid-template-columns: repeat(3, 1fr);
  }

  .col-2-1-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .col-2-1-1 div:nth-of-type(4n) {
    order: 1;
  }

  .col-2-1-1 div:nth-of-type(3n) {
    order: 2;
  }

  .col-4-2-1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .topic-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .col-3-2-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .col-2-2-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .col-3-3-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .col-4-2-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Flex */

.d-flex {
  display: flex;
}

.d-flex-inln {
  display: inline-flex;
}

.d-flex p:last-child {
  margin-bottom: 0;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-row-to-col {
  flex-direction: row;
}

.flex-ai-l {
  align-items: flex-start;
}

.flex-ai-c {
  align-items: center;
}

.flex-ai-r {
  align-items: flex-end;
}

.flex-jc-c {
  justify-content: center;
}

.flex-jc-sb {
  justify-content: space-between;
}

.flex-jc-sa {
  justify-content: space-around;
}

.flex-jc-se {
  justify-content: space-evenly;
}

.flex-jc-end {
  justify-content: flex-end;
}

.flex-jc-start {
  justify-content: flex-start;
}

.flex-basis-a {
  flex-basis: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

@media only screen and (max-width: 980px) {
}

@media only screen and (max-width: 767px) {
  .flex-row-to-col {
    flex-direction: column;
  }

  .flex-row-to-col > div .th-btn {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
}

/* Space */

p.no-spc,
.no-spc {
  margin-bottom: 0;
}

.main-wrapper .spc-b-xl {
  margin-bottom: var(--spc-xl);
}

.main-wrapper .spc-b-l {
  margin-bottom: var(--spc-l);
}

.main-wrapper .spc-b-m {
  margin-bottom: var(--spc-m);
}

.main-wrapper .spc-b-r {
  margin-bottom: 1rem;
}

.main-wrapper .spc-b-s {
  margin-bottom: var(--spc-s);
}

.main-wrapper .spc-b-xs {
  margin-bottom: var(--spc-xs);
}

.pall-xl {
  padding: var(--spc-xl);
}

.pall-l {
  padding: var(--spc-l);
}

.pall-m {
  padding: var(--spc-m);
}

.pall-r {
  padding: var(--spc-r);
}

.pall-s {
  padding: var(--spc-s);
}

.pall-xs {
  padding: var(--spc-xs);
}

.ptop-xl {
  padding-top: var(--spc-xl);
}

.ptop-l {
  padding-top: var(--spc-l);
}

.ptop-m {
  padding-top: var(--spc-m);
}

.ptop-r {
  padding-top: var(--spc-r);
}

.ptop-s {
  padding-top: var(--spc-s);
}

.ptop-xs {
  padding-top: var(--spc-xs);
}

.pbottom-xl {
  padding-bottom: var(--spc-xl);
}

.pbottom-l {
  padding-bottom: var(--spc-l);
}

.pbottom-m {
  padding-bottom: var(--spc-m);
}

.pbottom-r {
  padding-bottom: var(--spc-r);
}

.pbottom-s {
  padding-bottom: var(--spc-s);
}

.pbottom-xs {
  padding-bottom: var(--spc-xs);
}

.pright-xl {
  padding-right: var(--spc-xl);
}

.pright-l {
  padding-right: var(--spc-l);
}

.pright-m {
  padding-right: var(--spc-m);
}

.pright-r {
  padding-right: var(--spc-r);
}

.pright-s {
  padding-right: var(--spc-s);
}

.pright-xs {
  padding-right: var(--spc-xs);
}

.pleft-xl {
  padding-left: var(--spc-xl);
}

.pleft-l {
  padding-left: var(--spc-l);
}

.pleft-m {
  padding-left: var(--spc-m);
}

.pleft-r {
  padding-left: var(--spc-r);
}

.pleft-s {
  padding-left: var(--spc-s);
}

.pleft-xs {
  padding-left: var(--spc-xs);
}

.p-row {
  padding: 6vw 0;
}

.p-row-t {
  padding-top: 6vw;
}

.p-row-b {
  padding-bottom: 6vw;
}

.p-col {
  padding: 6vw;
}

.p-banner {
  padding: 6vw 0;
}

.v-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-align-ctr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.th-gap-xl {
  gap: var(--spc-xl);
}

.th-gap-l {
  gap: var(--spc-l);
}

.th-gap-m {
  gap: var(--spc-m);
}

.th-gap-r {
  gap: var(--spc-r);
}

.th-gap-s {
  gap: var(--spc-s);
}

.th-gap-xs {
  gap: var(--spc-xs);
}

@media only screen and (max-width: 980px) {
  .p-banner {
    padding: 12vw 0;
  }
}

@media only screen and (max-width: 480px) {
}

/* Size */

.ar-16x9 {
  aspect-ratio: 16 / 9;
}

.ar-1x1 {
  aspect-ratio: 1 / 1;
}

.ar-6x4 {
  aspect-ratio: 3 / 2;
}

.ar-3x1 {
  aspect-ratio: 3 / 1;
}

.w-100 {
  width: 100%;
}

/* Content */

// .content-container {
//   width:100%;
//   min-height: calc(100vh - var(--navHeight));
// }

.content-mw {
  max-width: 1400px;
  margin: 0 auto;
}

.th-ctr {
  margin: 0 auto;
}

.form-mw {
  max-width: 600px;
}

.th-panel {
  box-shadow: rgba(76, 76, 82, 0.5) 0px 12px 16px -6px;
  transition: all 0.3s ease-in-out;
  border: 1px solid white;
}

.th-panel:hover {
  box-shadow: none;
}

.full-screen {
  position: relative;
  width: 100vw;
  height: calc(100vh - var(--navHeight));
  overflow: hidden;
}

.split-screen {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - var(--navHeight));
}

.split-screen > div {
  flex: 1;
}

.split-screen-register {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.split-screen-register > div {
  flex: 1;
}

.split-register {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - var(--navHeight));
}

.th-register-col-parent {
  overflow: hidden;
  flex: 1;
}

.th-register-col-teacher {
  overflow: hidden;
  flex: 1;
}

.full-page {
  //min-height: calc(100vh - var(--navHeight));
  height: 100vh;
}

.mw-660 {
  max-width: 660px;
  margin: 0 auto;
}

.mw-480 {
  max-width: 480px;
  margin: 0 auto;
}

.th-br {
  border-radius: 4px;
}

@media only screen and (max-width: 980px) {
  .split-register {
    display: flex;
    flex-direction: column;
  }

  .split-screen {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--navHeight));
  }

  .split-screen > div:first-child {
    display: none !important;
  }

  .split-screen-register {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .split-screen-register > div:first-child {
    display: none !important;
  }

  .mw-660 {
    max-width: 100%;
  }

  .mw-480 {
    max-width: 100%;
  }
}

@media only screen and (max-width: 480px) {
}

/* Tables */

// .th-table-container {
//   overflow-x: scroll;
//   border: 1px solid var(--th-rainy-day);
//   padding: var(--spc-r);
//   border-radius: var(--spc-s);
// }

// .th-table {
//   width: 100%;
//   border-collapse: collapse;
// }

// .th-table tbody tr:nth-child(2n + 2) {
//   background-color: var(--th-white);
// }

// .th-table tbody tr:nth-child(2n + 1) {
//   background-color: var(--th-light-grey);
// }

// .th-table td,
// .th-table th {
//   padding: var(--spc-s);
// }

// .th-table thead th,
// .th-table tbody tr {
//   border: none;
// }

// .th-table tr th {
//   vertical-align: middle;
// }

// .th-table tr td {
//   vertical-align: middle;
// }

// .th-table tr td label {
//   margin-bottom: 0;
// }

// .th-table-action-col {
//   width: 0px;
//   white-space: nowrap;
// }

/* Admin */

.th-admin-image-container {
  position: relative;
  aspect-ratio: 1 / 1;
  padding: var(--spc-l);
  border: 1px solid var(--th-rainy-day);
}

.th-admin-btn {
  background-color: var(--th-yellow);
  border: 1px solid var(--th-yellow);
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  padding: var(--spc-s) var(--spc-m);
  border-radius: 4px;
  min-width: 80px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.th-admin-btn:hover {
  color: var(--th-black);
  background-color: var(--th-white);
  border: 1px solid var(--th-yellow);
}

.th-admin-link {
  background-color: transparent;
  color: var(--th-thundercloud);
  border: none;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
}

.th-admin-link i {
  color: var(--th-thundercloud);
  margin-right: var(--spc-xs);
  transition: all 0.3s ease-in-out;
}

.th-admin-link:hover {
  color: var(--th-purple);
}

.th-admin-link:hover i {
  color: var(--th-purple);
  transform: scale(1.15);
  transform-origin: center;
}

/* Text Styles */

.t-large {
  font-size: 3.5rem;
  line-height: 1;
}

.hw-font {
  font-family: "LondrinaSolid", cursive;
}

.display-font {
  font-family: "LuckiestGuy", cursive;
  text-transform: uppercase;
}

.t-shadow-yellow {
  text-shadow: rgba(249, 177, 4, 0.8) 4px 4px 0px;
}

.t-shadow-purple {
  text-shadow: rgba(72, 38, 131, 0.5) 4px 4px 0px;
}

.t-shadow {
  text-shadow: rgba(0, 0, 0, 0.3) 4px 4px 4px;
}

.t-hvy {
  font-weight: 900;
  letter-spacing: -1px;
}

.t-bld {
  font-weight: 700;
}

.t-light {
  font-weight: 300;
}

.t-upper {
  text-transform: uppercase;
}

.t-proper {
  text-transform: capitalize;
}

.t-ctr {
  text-align: center;
}

.t-grey {
  color: var(--th-mid-grey-3);
}

.t-yellow {
  color: var(--th-yellow);
}

.t-purple {
  color: var(--th-purple);
}

.t-error {
  color: var(--th-red);
}

.t-white {
  color: #ffffff;
}

.t-intro {
  font-size: 1.75rem;
  line-height: 1.25;
}

.t-mw {
  max-width: 600px;
  margin: 0 auto;
}

@media only screen and (max-width: 980px) {
}

@media only screen and (max-width: 480px) {
  .t-intro {
    font-size: 1.5rem;
  }
}

/* Backgrounds */

.bgc-pattern {
  background: var(--th-light-grey-1);
  background-repeat: repeat;
  background-size: 200px;
  background-attachment: fixed;
}

.bgc-dark {
  background: var(--th-black);
}

.bgc-grey {
  background: var(--th-rainy-day);
}

.bgc-white {
  background: #ffffff;
}

.bgc-light-grey {
  background: var(--th-light-grey-1);
}

.bgc-yellow {
  background: var(--th-yellow);
}

.bgc-grade-pink {
  background: rgb(138, 39, 127);
  background: linear-gradient(135deg, rgba(232, 51, 94, 1) 20%, rgba(138, 39, 127, 1) 100%);
}

.bgc-grade-sunshine {
  background: rgb(250, 210, 50);
  background: linear-gradient(135deg, rgba(250, 210, 50, 1) 30%, rgba(249, 177, 4, 1) 100%);
}

.bgc-grade-snowfall {
  background: rgb(242, 242, 245);
  background: linear-gradient(135deg, rgba(242, 242, 245, 1) 30%, rgba(222, 225, 230, 1) 100%);
}

.bgc-grade-sunset {
  background: rgb(227, 5, 27);
  background: linear-gradient(135deg, rgba(227, 5, 27, 1) 0%, rgba(249, 177, 4, 1) 100%);
}

.bgc-grade-dusk {
  background: rgb(138, 29, 127);
  background: linear-gradient(135deg, rgba(72, 38, 131, 1) 0%, rgba(231, 28, 119, 1) 100%);
}

.bgc-grade-starshine {
  background: rgb(29, 35, 98);
  background: linear-gradient(135deg, rgba(29, 35, 98, 1) 0%, rgba(0, 166, 208, 1) 100%);
}

.th-shadow-down {
  box-shadow: rgba(32, 32, 36, 0.1) 0px 12px 24px 0px, rgba(32, 32, 36, 0.4) 0px 10px 12px -6px;
}

.th-shadow-right {
  box-shadow: rgba(0, 0, 0, 0.6) 8px 0px 12px -6px, rgba(0, 0, 0, 0.25) 12px 0px 16px 0px;
}

/* Image Styles */

.th-img-fit {
  width: 100%;
  height: auto;
}

.th-img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease-in-out;
}

.th-img-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease-in-out;
}

.th-img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: all 0.3s ease-in-out;
}

a > img:only-child {
  display: block;
}

.th-img-mw {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 980px) {
  .th-img-mw {
    max-width: 50%;
  }

  .full-screen {
    overflow: auto;
  }
}

@media only screen and (max-width: 480px) {
  .th-img-mw {
    max-width: 40%;
  }

  .full-screen {
    overflow: auto;
  }
}

/* Slider */

// .th-slider-container {
//   display: flex;
//   flex-wrap: nowrap;
//   overflow-x: auto;
//   gap: var(--spc-m);
// }

// .th-slide {
//   box-sizing: border-box;
//   position: relative;
//   flex: 0 0 auto;
//   width: calc(33.33% - 16px);
//   overflow: hidden;
// }

// .th-slider-container .carousel .control-dots .dot {

// }

// .th-slider-container li.slide {
//   aspect-ratio: 3 / 2;
//   width: calc(33.33% - 24px);
// }

/* Home Page */

.th-page-banner {
  position: relative;
  width: 100%;
  height: 50vw;
  max-height: 700px;
  padding: var(--spc-l);
  overflow: hidden;
}

.th-page-banner-content {
  animation: fadeIn;
  animation-duration: 300ms;
  z-index: 1;
}

.th-empty-tile {
  border: 1px dashed var(--th-light-grey-3);
  transition: all 0.3s ease-in-out;
}

.th-empty-tile:hover {
  border-color: var(--th-mid-grey-1);
}

.th-empty-tile-label,
.th-empty-tile-label i {
  color: var(--th-mid-grey-1);
  transition: all 0.3s ease-in-out;
}

.th-empty-tile:hover .th-empty-tile-label i,
.th-empty-tile:hover .th-empty-tile-label {
  color: var(--th-purple);
}

.th-monster {
  position: absolute;
  bottom: -50%;
  right: 0%;
  width: 30vw;
  height: auto;
  transition: all 0.5s ease-in-out;
}

.th-monster-focus {
  bottom: -30%;
}

.card-icon-image {
  max-width: 100px;
}

.th-card-outer {
  border: 1px solid var(--th-rainy-day);
}

.th-card-outer:hover {
  border: 1px solid var(--th-steel);
}

@media only screen and (max-width: 980px) {
  .th-page-banner {
    height: auto;
  }
}

@media only screen and (max-width: 480px) {
}

/* Topic Catalogue Page */

.th-topic-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--th-white);
  transition: all 0.3s ease-in-out;
  // box-shadow: rgba(32, 32, 36, 0.1) 0px 12px 24px 0px, rgba(32, 32, 36, 0.4) 0px 10px 12px -6px;
  border-radius: 0.725rem;
}

.th-topic-card-container:hover {
  box-shadow: none;
  cursor: pointer;
}

.th-topic-card-image {
  border-radius: 0.525rem;
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
  overflow: hidden;
}

.th-topic-card-container:hover img {
  transform: scale(1.05);
  transform-origin: center;
}

.th-topic-card-desc {
  padding: var(--spc-s);
}

.th-topic-card-desc h2 {
  font-size: 1.5rem;
}

.th-topic-card-desc a {
  color: var(--th-thundercloud);
  text-decoration: none;
}

.th-topic-card-desc a:hover {
  color: var(--th-purple);
  text-decoration: none;
}

.th-topic-card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--spc-m);
  border-top: 1px solid var(--th-light-grey-3);
}

.th-topic-card-actions i {
  font-size: 1.25rem;
  margin-right: 0.3125rem;
  transition: all 0.3s ease-in-out;
}

.th-topic-card-actions i:last-child {
  margin-right: 0;
}

.th-topic-card-actions p {
  margin-bottom: 0;
}

.th-topic-card-icon {
  color: var(--th-steel);
}

.th-topic-card-icon:hover {
  color: var(--th-thundercloud);
}

.th-topic-card-icon-fav {
  color: var(--th-steel);
  margin-right: 0.3125rem;
}

.th-topic-card-icon-fav:hover {
  color: var(--red);
}

.th-topic-card-icon-fav.fa-solid {
  color: var(--red);
}

/* Topic Detail Page */

.spotlight {
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95vw;
  height: 95vw;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
}

.spotlight-focus {
  width: 66vw;
  height: 66vw;
}

.help-content-container {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  background-color: #fffbf0;
  border-color: #fffbf0;
}

.hide {
  opacity: 0;
  height: 0;
}

.show {
  opacity: 1;
  height: auto;
}

.th-topic-banner-container {
  width: 100%;
  aspect-ratio: 3 / 1;
}

@media only screen and (max-width: 980px) {
  .th-topic-banner-container {
    aspect-ratio: 2 / 1;
  }
}

@media only screen and (max-width: 480px) {
  .th-topic-banner-container {
    aspect-ratio: 3 / 2;
  }
}

/* Animations */

.th-fade-in {
  animation: fadeIn 0.8s;
  animation-timing-function: ease-in-out;
  -webkit-animation: fadeIn 0.8s;
  -moz-animation: fadeIn 0.8s;
  -o-animation: fadeIn 0.8s;
  -ms-animation: fadeIn 0.8s;
}

.th-burst {
  animation: burst-in 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation: burst-in 1s;
  -moz-animation: burst-in 1s;
  -o-animation: burst-in 1s;
  -ms-animation: burst-in 1s;
}

// .moon-in {
//   animation: slide-in-moon 1.5s;
//   animation-timing-function: ease-in-out;
//   -webkit-animation: slide-in-moon 1.5s;
//   -moz-animation: slide-in-moon 1.5s;
//   -o-animation: slide-in-moon 1.5s;
//   -ms-animation: slide-in-moon 1.5s;
// }

.monster-in {
  animation: pop-up-monster 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation: pop-up-monster 1s;
  -moz-animation: pop-up-monster 1s;
  -o-animation: pop-up-monster 1s;
  -ms-animation: pop-up-monster 1s;
}

@keyframes fadeIn {
  0% {
    transform: scale(0.66);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// @keyframes float {
//   0% {
//     transform: translateY(0px) translateX(-20px) rotate(0) scale(1);
//   }

//   50% {
//     transform: translateY(60px) translateX(-60px) rotate(9deg) scale(1.2);
//   }

//   100% {
//     transform: translateY(0px) translateX(-20px) rotate(0) scale(1);
//   }
// }

@keyframes burst-in {
  0% {
    width: 0vw;
    height: 0vw;
  }

  25% {
    width: 100vw;
    height: 100vw;
  }

  50% {
    width: 80vw;
    height: 80vw;
  }

  100% {
    width: 95vw;
    height: 95vw;
  }
}

// @keyframes slide-in-moon {
//   0% {
//     left: 150%;
//   }

//   100% {
//     left: 110%;
//   }
// }

@keyframes pop-up-monster {
  0% {
    bottom: -80%;
  }

  100% {
    bottom: -50%;
  }
}

.token-check {
  position: relative;
  text-align: center;
  font-weight: bold;
  margin: auto 10px;
  display: none;
}

.mngedash {
  background-color: #861582;

  &__tiles {
    background-color: #003557;

    &li {
      display: inline-block;
      width: 200px;
    }
  }
}

.css-1utwwx4 {
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 8px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3em;
  padding: 1px;
}

.css-1utwwx4 input {
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 3em;
}

label {
  display: inline-block;
}

.css-1utwwx4 input:checked ~ span:last-of-type,
.css-1utwwx4 input:not(:checked) ~ span:first-of-type {
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px;
  color: rgb(51, 51, 51);
  padding: 7px 15px;
}

.css-1utwwx4 span:first-of-type {
  padding-right: 8px;
}

.css-1utwwx4 span {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  display: inline-block;
  padding: 7px 15px;
  transition: all 100ms ease-out 0s;
  user-select: none;
  border-radius: 3em;
  color: rgba(51, 51, 51, 0.6);
  background: transparent;
}

.css-gg4vpm {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.css-6hhrgj.css-6hhrgj th {
  color: rgba(51, 51, 51, 0.75);
  padding: 10px 15px;
}

.page-content {
  padding-bottom: 50px;
}

.mt50 {
  margin-top: 50px;
}

.form-scroll {
  overflow-y: scroll;
}

.image-tiles {
  width: 100%;
}

.image-tiles .image-tile img {
  width: 30%;
  max-width: 300px;
  max-height: 300px;
}

.th-check {
  margin-left: 22px;
}

// .share-expanded {
//   display: none;
//   transition: all 0.3s ease-in-out;
// }

// .share-expanded.open {
//   display: block;
// }

// .th-topic-share-icons {
//   font-size: 1rem;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   gap: 12px;
// }

// .th-social-share-btn i {
//   width: 32px;
//   height: 32px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-radius: 4px;
//   color: #ffffff;
//   background-color: var(--th-steel);
//   transition: all 0.3s ease-in-out;
// }

// .th-social-share-btn i:hover {
//   padding: 8px;
//   border-radius: 4px;
//   background-color: var(--th-yellow);
// }

// .sser-box {
//   margin-top: 5rem;
// }

// .ssor-logo {
//   height: auto;
//   max-width: 280px;
// }

// .onetimecode {
//   font-size: 1.75rem;
// }

.welcome-setting {
  position: absolute;
  right: 5rem;
  bottom: 2rem;
  width: 280px;
  height: 80px;
  display: block;
}

// .cookie-banner {
//   position: fixed;
//   bottom: 0px;
//   border-top: 1px solid var(--th-snowfall);
//   height: auto;
//   padding: var(--spc-m);
//   margin: 0px;
//   background-color: #ffffff;
//   width: 100%;
// }

// .cookie-content-container {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   gap: var(--spc-m);
// }

.legal-section {
  padding: var(--spc-l) 0;
  border-top: 1px solid var(--th-light-grey-3);
}

.legal-section h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: var(--spc-r);
  line-height: 1.15em;
}

.legal-section ul li li,
.legal-section ol li li:last-child {
  margin-bottom: 1em;
}

.legal-section ol ul {
  list-style-type: disc;
}

@media only screen and (max-width: 980px) {
}

@media only screen and (max-width: 767px) {
  .cookie-content-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 480px) {
}

.th-card-container {
  background-color: transparent;
}

.th-card {
  text-align: center;
  background-color: white;
  border-radius: 8px;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: rgba(200, 200, 200, 1) 0px 12px 16px -6px;
  transition: box-shadow 0.3s ease-in-out;
  mix-blend-mode: multiply;
}

.th-card:hover .shadow {
  box-shadow: none;
}

.th-card h2 {
  font-size: 28px;
}

.th-card-inner-container {
  padding: var(--spc-l);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--spc-m);
}

.link-label {
  color: var(--th-purple);
  text-decoration: underline;
  cursor: pointer;
}

.circle-border {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--th-steel);
  padding: 1.25em 0.7em;
  font-size: 28px;
}

.navigation-font {
  font-size: 15px;
  font-weight: 700;
  color: var(--th-mid-grey-2);
  cursor: pointer;
}

.navigation-font:hover {
  color: var(--th-black);
}

.display-page-again {
  bottom: 30px;
  right: 0;
}

.topic-detail-image {
  cursor: pointer;
}

.one-time-code-box {
  background: rgb(250, 210, 50);
  background: linear-gradient(135deg, rgba(250, 210, 50, 1) 30%, rgba(249, 177, 4, 1) 100%);
  box-shadow: rgba(76, 76, 82, 0.5) 0px 12px 12px 0px;
}
