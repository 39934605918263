html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  height: -webkit-fill-available;
}

iframe {
  width: 100vw;
  height: 100%;
  border: none;
  display: block;
  position: fixed;
}

#message {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: lightgoldenrodyellow;
  color: black;
  padding: 20px;
  font-family: sans-serif;
}