// Slider

.slider-block {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    &__content {
        width: 100%;
        max-width: 100%;
        padding: 0;
    }
}

.slider {

    &__large-slide {
        position: relative;
        width: 100%;
        aspect-ratio: 3 / 1;
        padding: $spc-l;
        overflow: hidden;

        @include bp-lg {
            aspect-ratio: 2 / 1;
        }
        
        @include bp-sm {
            aspect-ratio: 3 / 2;
        }
    }
    
    &__link {
        display: inline-block;
        position: absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;

        & img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.3s ease-in-out;
        }
    }

    // Arrows

    & .carousel.carousel-slider .control-arrow {
        display: none;

        &:before {
            transition: all 0.3s ease-in;
            opacity: 0.4;
            display: inline-block;
            border: solid white;
            border-width: 0 3px 3px 0;
            padding: 3px;
            width: 20px;
            height: 20px;
            content: '';
        }

        &:hover:before {
            opacity: 1;
        }


        @include bp-md {
            display: block;
            transition: all 0.3s ease-in;
            position: absolute;
            z-index: 2;
            border: 0;
            padding: 0 20px;
            opacity: 1;
            margin: 0;
            cursor: pointer;
        }
    }

    & .carousel.carousel-slider .control-prev.control-arrow {
        background: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
        left: 0;

        &:hover {
            background: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
            left: 0;
        }

        &:before {
            margin-left: 10px;
            transform: rotate(135deg);
        }
    }

    & .carousel.carousel-slider .control-next.control-arrow {
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
        right: 0;

        &:hover {
            background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
            right: 0;
        }

        &:before {
            margin-right: 10px;
            transform: rotate(-45deg);
        }
    }

    // Dots

    & ul.control-dots {
        position: absolute;
        margin: 0;
        bottom: $spc-r;

        @include bp-md {
            display: none;
        }

        & li.dot {
            background: none;
            box-shadow: none;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            border: 1px solid var(--th-light-grey-3);
            border-radius: 50%;
            width: 10px;
            height: 10px;
            cursor: pointer;
            display: inline-block;
            margin: 0 6px;

            &:hover {
                border: 1px solid var(--th-mid-grey-1);
                background: var(--th-mid-grey-1);
                box-shadow: none;
            }
        }

        & li.dot.selected {
            border: 1px solid var(--th-mid-grey-1);
            background: var(--th-mid-grey-1);
            box-shadow: none;
        }
    }
}