// Topic Card

.topic-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    background-color: $c-white;
    text-decoration: none;
    border-radius: 0.8rem;
    box-shadow: $bs-card;  
}

.topic-card-content {
    width: 100%;
    max-width: 100%;

    &__image {
        position: relative;
        aspect-ratio: 3 / 2;
        margin: $spc-s $spc-s 0 $spc-s;
        border-radius: .4rem;
        
        overflow: hidden;

        &:hover img {
            transform: scale(1.05);
            transform-origin: center;
        }

        & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-width: 100;
            object-fit: cover;
            object-position: center;
            transition: $transition;
        }
    }
}

.topic-card-desc {
    padding: $spc-r;

    &__title {
        color: $c-purple;
        font-size: 2.4rem;
        font-weight: 900;
        margin-bottom: $spc-xs;
        
    }

    &__text {
        color: $c-grey;
        font-size: 1.6rem;
    }
}

.topic-card-share {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: $spc-s;

    & i {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        color: $c-white;
        font-size: 1.6rem;
        width: 3.2rem;
        height: 3.2rem;
        background-color: $c-grey;

        &:hover {
            background-color: $c-purple;
        }
        
    }
}

.share-expanded {
    display: none;
    transition: all 0.3s ease-in-out;

    &.open {
        display: block;
    }
  }


  .topic-status-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: var(--spc-m);
    padding-right: var(--spc-l);

    &__text {
        font-size: 1.4rem;
        margin-bottom: 0;
    }
  }

  .topic-completed-tag-section{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }


  .topic-completed-tag {
    position: absolute;
    right: 5%;
    width: auto;
    padding: 15px 10px 5px 10px;
    z-index: 10;
    background-color: #32C878;
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 700;
}


  .custom-checkbox{
    height: 18px;
    width: 18px;
    background-color: #50CDBE;
    accent-color: #673ab7;
  }

  

.topic-card-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid $c-grey-xl;
    padding: $spc-r;

    &__text {
        font-size: 1.4rem;
        margin-bottom: 0;
    }

    &__icons {
        color: $c-grey;
        font-size: 1.8rem;
        margin-right: 0.4rem;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $c-black;
        }

        &--fav {
            color: $c-grey;
            
            &:hover {
                color: $c-red;
            }

            &.fa-solid {
                color: $c-red;
            }
        }
    }
}

// Empty Topic Card

.empty-topic-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    background-color: $c-white;
    text-decoration: none;
    border-radius: 0.8rem;
    box-shadow: $bs-card;  
}

.empty-topic-card-content {
    width: 100%;
    max-width: 100%;

    &__image {
        background-color: $c-grey-xl;
        aspect-ratio: 3 / 2;
        margin: $spc-s $spc-s 0 $spc-s;
        border-radius: .4rem;
    }
}

.empty-topic-card-desc {
    padding: $spc-r;

    &__title {
        color: $c-black;
        font-size: 2.4rem;
        font-weight: 900;
        margin-bottom: $spc-xs;
        
    }

    &__text {
        color: $c-grey;
        font-size: 1.6rem;
    }
}

.empty-topic-card-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-top: 1px solid $c-grey-xl;
    padding: $spc-r;
}