/* Buttons */

.th-btn.fw-btn {
    width: 100%;
    max-width: 100%;
  }
  
  .th-btn.btn-shadow, a.th-btn.btn-shadow {
    box-shadow: rgba(0, 0, 0,.6) 0px 5px 8px -6px;
  }
  
  .th-btn.btn-shadow:hover, a.th-btn.btn-shadow:hover {
    box-shadow: none;
  }
  
  .th-btn, a.th-btn {
    min-width: 140px;
    max-width: max-content;
    white-space: nowrap;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    padding: 14px 24px;
    transition: all 0.3s ease-in-out;  
  }
  .th-btn:hover, a.th-btn:hover {
    text-decoration: none;
  }
  .yellow-btn {
    color:var(--th-black);
    background-color: var(--th-yellow);
    background-image: linear-gradient(180deg, rgba(250,210,50,1) 75%, rgba(249,177,4,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
    box-shadow: rgba(0, 0, 0,.6) 0px 5px 8px -6px;
  }
  .yellow-btn:hover {
    color:var(--th-black);
    background-position: 0% 75%;
    box-shadow: none;
  }
  .purple-btn {
    color: var(--th-white);
    background-color: var(--th-purple);
    background-image: linear-gradient(180deg, var(--th-purple) 70%, rgba(92,36,131,1) 100%);
    background-size: 100% 200%;
    background-position: 0% 100%;
  }
  .purple-btn:hover {
    background-position: 0% 70%;
  }
  .red-btn {
    color: var(--th-white);
    background-color: var(--th-red);
    background-image: linear-gradient(180deg, var(--th-red) 70%, rgb(131, 36, 36) 100%);
    background-size: 100% 200%;
    background-position: 0% 100%;
  }
  .red-btn:hover {
    background-position: 0% 70%;
  }
  .icon-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .th-download-btn {
    color: #120f12;
    font-weight: 600;
    background-color: var(--th-snowfall);
    padding: var(--spc-s) var(--spc-m);
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }
  .th-download-btn:hover {
    background-color: var(--th-yellow);
    color:var(--th-purple);
  }
  .th-download-btn i {
    font-size: 1.25rem;
    margin-right: var(--spc-s);
  }
  
  .th-sml-btn, a.th-sml-btn {
    display: block;
    min-width: 140px;
    max-width: max-content;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    padding: 12px 20px;
    transition: all 0.3s ease-in-out;  
  }
  .th-sml-btn, a.th-sml-btn:hover {
    text-decoration: none;
  }
  
  .primary-btn {
    color:var(--th-black);
    background-color: var(--th-yellow);
    background-image: linear-gradient(180deg, rgba(250,210,50,1) 70%, rgba(249,177,4,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .primary-btn:hover {
    color:var(--th-black);
    background-position: 0% 75%;
  }
  .secondary-btn {
    color:var(--th-black);
    background-color: rgba(240,240,240,1);
    background-image: linear-gradient(180deg, rgba(220,220,220,1) 70%, rgba(180,180,180,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .secondary-btn:hover {
    color:var(--th-black);
    background-position: 0% 75%;
  }
  .info-btn {
    color: var(--th-white);
    background-color: rgba(30,180,240,1);
    background-image: linear-gradient(180deg, rgba(30,180,240,1) 70%, rgba(30,150,210,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .info-btn:hover {
    color: var(--th-white);
    background-position: 0% 75%;
  }
  .success-btn {
    color: var(--th-white);
    background-color: rgba(50,200,120,1);
    background-image: linear-gradient(180deg, rgba(50,200,120,1) 70%, rgba(40,170,110,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .success-btn:hover {
    color: var(--th-white);
    background-position: 0% 75%;
  }
  .warning-btn {
    color: var(--th-white);
    background-color: rgba(250,130,0,1);
    background-image: linear-gradient(180deg, rgba(250,130,0,1) 70%, rgba(230,100,0,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .warning-btn:hover {
    color: var(--th-white);
    background-position: 0% 75%;
  }
  .danger-btn {
    color: var(--th-white);
    background-color: rgba(240,50,50,1);
    background-image: linear-gradient(180deg, rgba(240,50,50,1) 70%, rgba(200,30,30,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .danger-btn:hover {
    color: var(--th-white);
    background-position: 0% 75%;
  }
  .light-btn {
    color: var(--th-black);
    background-color: rgba(255,255,255,1);
    background-image: linear-gradient(180deg, rgba(255,255,255,1) 70%, rgba(220,220,220,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .light-btn:hover {
    color: var(--th-black);
    background-position: 0% 75%;
  }
  .dark-btn {
    color: var(--th-white);
    background-color: rgba(55,5,55,1);
    background-image: linear-gradient(180deg, rgba(55,55,55,1) 70%, rgba(18,18,18,1) 90%);
    background-size: auto 200%;
    background-position: 0% 100%;
  }
  .dark-btn:hover {
    color: var(--th-white);
    background-position: 0% 75%;
  }

  .fp-btn-container {
    position: absolute;
    bottom: 0;
    right:0;
    padding: var(--spc-l)
  }

  .fp-btn {
    min-width: 140px;
    max-width: max-content;
    white-space: nowrap;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    padding: 14px 24px;
    transition: all 0.3s ease-in-out; 
  }

  .inline-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spc-r);
}

/* MEDIA QUERIES */

/* Tablet */

@media only screen and (max-width: 980px) {

}

/* Large Mobile */

@media only screen and (max-width: 767px) {
  .fp-btn-container {
    position: relative;
    bottom: 0;
    right:0;
  }
}

/* Mobile */

@media only screen and (max-width: 480px) {

}