html {
	box-sizing: border-box;
	font-size: 62.5%;
	
	scroll-behavior: smooth;
}
*, *:before, *:after {
	box-sizing: inherit;
}
body {
	color: $c-black;
	font-family: $f-main;
	font-size: $fs-body;
	font-weight: 300;
	font-style: normal;
	background-color: $c-white;

	@include bp-md {
		font-size: $fs-body-sm;
	}
}

.main-wrapper {
	width: 100%;
	left: 0;
	padding-top: $h-header;
	position: relative;
	transition: $transition;
	
	@include bp-lg {
	  padding-top: $h-header-sm;
	}
  }

.mw-push {
	margin-left:380px;

	@include bp-sm {
		margin-left:100%;
	}
}

ul, ol {
	padding: 0;
	margin-left: 2rem;
}
li {
	margin-bottom: 1rem;
	
	&:last-child {
		margin-bottom: 1rem;
	}
}
sup {
	font-size: 80%;
	top: -0.3rem;
}
sub {
	font-size: 30%;
	bottom: -0.3rem;
}
blockquote {
	margin-block-start: 4rem;
	margin-block-end: 4rem;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-left: 1.6rem;
	display: block;
	
	font-size: 2rem;
	
	border-left: .6rem solid #000;
	
	@include bp-md {
		font-size: 2rem;
	}
	
	q {
		margin-bottom: 0;
		display: block;
		
		line-height: 1.25em;
		
		quotes: "\201C" "\201D";
	}
	
	cite {
		font-size: 1.4rem;
		font-style: italic;
		font-weight: 300;
		line-height: 1em;
		
		color: $c-black;
	}
}

hr {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-top: 3rem;
	padding-top: 3rem;
	width: 100%;
	max-width: 100%;
	
	border-top: 0.1rem solid #000;
	border-left: none; 
	border-bottom: none; 
	border-right: none;
	
	@include bp-md {
		margin-top: 2rem;
		padding-top: 2rem;
	}
}