// Banner Block

.login-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $lg-white;
}

.login-block-card {
    background-color: $c-white;
    border-radius: $br-lg;
    width: 100%;
    max-width: 40rem;
    padding: $spc-m;
    box-shadow: $bs-card;
}

.login-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spc-m;

    &__form {
        width: 100%;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid $c-grey-l;
        margin: 0 auto $spc-r auto;

        & i {
            color: $c-purple;
            font-size: 3rem;
        }
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;
        line-height: 1;
        margin-bottom: $spc-s;
    }

    &__text {
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 10;
    }

    &__code {
        font-size: 4rem;
        font-weight: 900;
        text-align: center;
        line-height: 1;
        margin-bottom: $spc-r;
    }

}

.login-card-footer {
    width: 100%;
    border-top: 1px solid $c-grey-xl;
    padding-top: $spc-m;

    &__link {
        font-size: 1.4rem;
        line-height: 1;
        text-align: center;
        margin-bottom: $spc-s;

        & span {
            cursor: pointer;
            color: $c-purple;
        }
    }
}

.switch-btn {
    cursor: pointer;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: $spc-m;

    color: $c-black;
    font-family: $f-main;
    font-size: 1.6rem;
    font-weight: 600;
    transition: $transition;

    & i {
        font-size: 2.2rem;
        margin-right: .5rem;
    } 
}