/* ALERTS */

.th-alert-container.alert {
    position:absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 999;
}

.th-alert-content {
    width: 100%;
    max-width: 480px;
    background-color: white;
    padding: var(--spc-r);
}

/* MEDIA QUERIES */

/* Tablet */

@media only screen and (max-width: 980px) {

}

/* Large Mobile */

@media only screen and (max-width: 767px) {

}

/* Mobile */

@media only screen and (max-width: 480px) {

}